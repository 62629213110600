package matekit.matekit.utilityfunctions

import androidx.compose.ui.text.intl.Locale

fun GenitiveCase(szám: Int): String {//birtokos esetbe kerül a szám
    // Log.d(TAG, Locale.getDefault().getDisplayLanguage())
    if (Locale.current.language == "hu") {
        if (szám % 1000 == 0) return "$szám-nek"
        if (szám % 100 == 0) return "$szám-nak"
        var számvége = szám % 100//.toString().takeLast(2).toInt()
        if (számvége % 10 != 0) {
            var legvége = számvége % 10
            when (legvége) {
                1 -> return "$szám-nek"
                2 -> return "$szám-nek"
                3 -> return "$szám-nak"
                4 -> return "$szám-nek"
                5 -> return "$szám-nek"
                6 -> return "$szám-nak"
                7 -> return "$szám-nek"
                8 -> return "$szám-nak"
                9 -> return "$szám-nek"
            }
        } else { //számvége=szám%10
            when (számvége) {
                10 -> return "$szám-nek"
                20 -> return "$szám-nak"
                30 -> return "$szám-nak"
                40 -> return "$szám-nek"
                50 -> return "$szám-nek"
                60 -> return "$szám-nak"
                70 -> return "$szám-nek"
                80 -> return "$szám-nak"
                90 -> return "$szám-nek"
            }
        }
    }
    return (szám.toString())
}

fun InstrumentalCase(szám: Int): String {
    if (Locale.current.language  == "hu") {
        if (szám % 1000 == 0) return "$szám-rel"
        if (szám % 100 == 0) return "$szám-zal"
        var számvége = szám % 100//.toString().takeLast(2).toInt()
        if (számvége % 10 != 0) {
            var legvége = számvége % 10
            when (legvége) {
                1 -> return "$szám-gyel"
                2 -> return "$szám-vel"
                3 -> return "$szám-mal"
                4 -> return "$szám-gyel"
                5 -> return "$szám-tel"
                6 -> return "$szám-tal"
                7 -> return "$szám-tel"
                8 -> return "$szám-cal"
                9 -> return "$szám-cel"
            }
        } else { //számvége=szám%10
            when (számvége) {
                10 -> return "$szám-zel"
                20 -> return "$szám-szal"
                30 -> return "$szám-cal"
                40 -> return "$szám-nel"
                50 -> return "$szám-nel"
                60 -> return "$szám-nal"
                70 -> return "$szám-nel"
                80 -> return "$szám-nal"
                90 -> return "$szám-nel"
            }
        }
    }
    return (szám.toString())
}


fun Int. accusativeCase(): String {//birtokos esetbe kerül a szám
    // Log.d(TAG, Locale.getDefault().getDisplayLanguage())
    if (Locale.current.language == "hu") {
        if (this % 1000 == 0) return "$this-nek"
        if (this % 100 == 0) return "$this-nak"
        var számvége = this % 100//.toString().takeLast(2).toInt()
        if (számvége % 10 != 0) {
            var legvége = számvége % 10
            when (legvége) {
                1 -> return "$this-et"
                2 -> return "$this-t"
                3 -> return "$this-at"
                4 -> return "$this-et"
                5 -> return "$this-öt"
                6 -> return "$this-ot"
                7 -> return "$this-et"
                8 -> return "$this-at"
                9 -> return "$this-et"
            }
        } else { //számvége=szám%10
            when (számvége) {
                10 -> return "$this-et"
                20 -> return "$this-at"
                30 -> return "$this-at"
                40 -> return "$this-et"
                50 -> return "$this-et"
                60 -> return "$this-at"
                70 -> return "$this-et"
                80 -> return "$this-at"
                90 -> return "$this-et"
            }
        }
    }
    return (this.toString())
}

fun Int.szor_szer(): String {
    // Log.d(TAG, Locale.getDefault().getDisplayLanguage())
    if (Locale.current.language == "hu") {
        if (this % 1000 == 0) return "$this-szer"
        if (this % 100 == 0) return "$this-szor"
        var számvége = this % 100//.toString().takeLast(2).toInt()
        if (számvége % 10 != 0) {
            var legvége = számvége % 10
            when (legvége) {
                1 -> return "$this-szer"
                2 -> return "$this-szer"
                3 -> return "$this-szor"
                4 -> return "$this-szer"
                5 -> return "$this-ször"
                6 -> return "$this-szor"
                7 -> return "$this-szer"
                8 -> return "$this-szor"
                9 -> return "$this-szer"
            }
        } else { //számvége=szám%10
            when (számvége) {
                10 -> return "$this-szer"
                20 -> return "$this-szor"
                30 -> return "$this-szor"
                40 -> return "$this-szer"
                50 -> return "$this-szer"
                60 -> return "$this-szor"
                70 -> return "$this-szer"
                80 -> return "$this-szor"
                90 -> return "$this-szer"
            }
        }
    }
    return (this.toString())
}