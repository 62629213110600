package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import com.ionspin.kotlin.bignum.decimal.BigDecimal
import com.ionspin.kotlin.bignum.decimal.toBigDecimal
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.removetrailingzeros
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.responseToast
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.random.Random

@Composable
fun tizhatvanyUI(navcontroller:NavHostController,TAG: String, ) {
    Surface(
        modifier = Modifier.fillMaxSize(), color = MaterialTheme.colorScheme.background
    ) {
        val vm = viewModel { TizhatvanyViewModel( TAG ) }
        val uiState by vm.uiState.collectAsState()
        val coroutineScope = rememberCoroutineScope()
        LaunchedEffect(Unit) {
            vm.generateNewTask()
        }
        Column(modifier = Modifier.fillMaxSize()) {

            borderedbasicfontsizeTextView(tasktext = uiState.feladat,)

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Center
            ) {

                universalCheckField(
                    uiState.userInput,
                    enterText = { vm.entertext(it) },
                    check = { coroutineScope.launch { vm.check()} }
                )

                Spacer(Modifier.width(8.dp))
                Button(
                    onClick = {
                        coroutineScope.launch { vm.check()}
                    },
                ) {
                    Text(stringResource( Res.string.Check))
                }
    LatexLogButton(navcontroller,TAG)
            }
        }
        backButton(navcontroller)
        responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.showsolution)
    }
}

data class TizhatvanyUIState(
    val feladat: String = "",
    val solution: String = "",
    val showsolution: String = "",
    val numberofsolved: Int = 0,
    val isCorrect: Boolean = false,
    val userInput: String = ""
)

class TizhatvanyViewModel( tag: String,) : ViewModel() {
    val tag = tag

    private val _uiState = MutableStateFlow(TizhatvanyUIState())
    val uiState: StateFlow<TizhatvanyUIState> = _uiState.asStateFlow()

    fun generateNewTask() {
        //osztás true, szorzás false
        // val df = DecimalFormat("#.#")
        //   df.maximumFractionDigits=32
        var number: BigDecimal = (1..250).random().toBigDecimal() //veszünk egy számot egy és 2500 között
        val elosszuk = Random.nextBoolean()//eldöntjük, hogy tizedes tört legyen-e belőle
        if (elosszuk) number= number.divide(1000.toBigDecimal())//ha igen, akkor leosztunk ezerrel

        val e = (-3..3).filterNot { it == 0 }.random()
        val tízhatvány = if (e > 0) 10.toBigDecimal().pow(e) else BigDecimal.ONE.divide(10.toBigDecimal().pow(-e))
        var osztás = true//kezdésnek legyen osztás
        if (true) {//ide még írhatunk feltételt ha szeretnénk
            osztás = Random.nextBoolean()
        }
        val operator = if (osztás) ":" else "*".toMultiplicationdot()
        var task = "Mennyi ${number.toPlainString()}$operator${tízhatvány.toPlainString()}?"
        if (number == tízhatvány && osztás) {//olyat ne adjunk fel,hogy saját magával osztunk
            generateNewTask()
        }

        var solution = if (osztás) (number.divide (tízhatvány)) else (number.multiply(tízhatvány) )

        _uiState.update {
            it.copy(
                feladat = task,
                solution = solution.toPlainString()
            )
        }
    }

    fun entertext(enteredText: String) {
        _uiState.update {
            it.copy(
                userInput = enteredText
            )
        }
    }

    suspend fun check() {
        val solution = uiState.value.solution.replace(".", ",")//todo ezt majd lokalizációval kezelni kell
        val userInput = uiState.value.userInput.replace(".", ",").removetrailingzeros()//todo ezt majd lokalizációval kezelni kell //TODO strip trailing zeros
        val isCorrect: Boolean
        if (userInput == solution) {
            isCorrect = true
        } else {
            isCorrect = false
        }
        val showSolutionIfUserWrong = getString(Res.string.WrongSolution, solution)
        _uiState.update {
            it.copy(
                isCorrect = isCorrect,
                numberofsolved = uiState.value.numberofsolved + 1,
                showsolution = showSolutionIfUserWrong
            )
        }


        LaTexLogWriteDesktop(
            isCorrect,
            _uiState.value.userInput.toString(),
            showSolutionIfUserWrong,
            tag,
            _uiState.value.feladat,
            taskLaTexWrap = false
        )
        generateNewTask()
        entertext("")
    }


}