@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package matekit_multiplatform.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val JetBrainsMono_Regular: FontResource by 
      lazy { init_JetBrainsMono_Regular() }

  public val NotoSans_Regular: FontResource by 
      lazy { init_NotoSans_Regular() }

  public val jetbrainsmonoregular: FontResource by 
      lazy { init_jetbrainsmonoregular() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("JetBrainsMono_Regular", CommonMainFont0.JetBrainsMono_Regular)
  map.put("NotoSans_Regular", CommonMainFont0.NotoSans_Regular)
  map.put("jetbrainsmonoregular", CommonMainFont0.jetbrainsmonoregular)
}

internal val Res.font.JetBrainsMono_Regular: FontResource
  get() = CommonMainFont0.JetBrainsMono_Regular

private fun init_JetBrainsMono_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/font/JetBrainsMono-Regular.ttf", -1, -1),
    )
)

internal val Res.font.NotoSans_Regular: FontResource
  get() = CommonMainFont0.NotoSans_Regular

private fun init_NotoSans_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NotoSans_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/font/NotoSans-Regular.ttf", -1, -1),
    )
)

internal val Res.font.jetbrainsmonoregular: FontResource
  get() = CommonMainFont0.jetbrainsmonoregular

private fun init_jetbrainsmonoregular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:jetbrainsmonoregular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/font/jetbrainsmonoregular.ttf", -1, -1),
    )
)
