package matekit.matekit.taskgenerators
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.update
import androidx.compose.material3.*
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import kotlinx.coroutines.launch
import matekit.matekit.ui.SegmentedButtonItem
import matekit.matekit.ui.SegmentedButtons
import matekit.matekit.ui.SegmentedButtonsDefaults
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton

import matekit.ui.generatorSettings
import matekit.ui.paramField
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.simpleLogButton
import matekit.ui.uifont
import matekit.ui.widthtype
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.D
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.customrange
import matekit_multiplatform.composeapp.generated.resources.from
import matekit_multiplatform.composeapp.generated.resources.jetbrainsmonoregular
import matekit_multiplatform.composeapp.generated.resources.to
import org.jetbrains.compose.resources.stringResource
import kotlin.math.min


data class IrasbeliUIState(
    val numberRangeStartS: String = "200",
    val numberRangeEndS: String = "500",
    val numberRange: IntRange = (2..400),
    val number1Range: IntRange = (2..50),
    val number2Range: IntRange = (2..50),
    val number1: Int,
    val number2: Int,
    val rowLength: Int,
    val operations: Set<String> = setOf("+", "-", "*".toMultiplicationdot(),":"),
    val operation: String = "+", // "+", "-", "*", "/"
    val height: Int = 3, // 3 for addition and subtraction
    // val partialResultList: List<List<String>>,
    val displayList: List<List<String>>,// a display listát módosíthatja a diák. engedjük meg, hogy bármelyik módosítsa.
    val solutionList: List<List<String>> = listOf(),
    // val userinputList: List<String> = listOf(),
    val unknownCellIndex: Set<Cellindex> = setOf(Cellindex(0, 0)),
    val divisionRemainder: Int = 0,//ezzel egyelőre nem foglalkozunk
    val isCorrect: Boolean = false,
    val numberofsolved: Int = 0,
    val showsolution: String = "",
    val solution: String = "",
    val shift: String = "left",//balra vagy jobbra  toljuk a szorzást,
    val számkörenabled:Boolean=false,
    val számkör : IntRange = (1..50),
    val extra: String = ""
)


class IrasbeliViewModel(
) : ViewModel() {
    val tag = "IrasbeliMuveletek"
    private val _uiState = MutableStateFlow(
        IrasbeliUIState(
            number1 = 0,
            number2 = 0,
            rowLength = 0,
            operation = "+",
            //partialResultList = listOf(),
            displayList = listOf()
        )
    )
    val uiState: StateFlow<IrasbeliUIState> = _uiState

    init {
        generateNewProblem()
    }

    fun generateNewProblem() {
        val filteredrange = _uiState.value.numberRange.toList().filter { it!=0&& it!=1&& it!=-1}//nullával, eggyel, mínusz eggyel való műveletek itt nem érdekesek

        var number1 = (filteredrange[0]..filteredrange.last()).random()
        var number2 = (filteredrange[0]..filteredrange.last()).random()
//        val number1 = (_uiState.value.number1Range.first.._uiState.value.number1Range.last).random()
//        val number2 = (_uiState.value.number2Range.first.._uiState.value.number2Range.last).random()
        var operation = try {
            _uiState.value.operations.random()
        } catch (e: Exception) {
            "+"//ha nem választott ki egyet sem, akkor legyen összeadás
        }

        //  operation=":"//todo remove this line

        if(operation=="*".toMultiplicationdot()){//nagyobb számot szorozzuk a kisebb számmal
            if(number1<number2){ //ha a második szám nagyobb, mint az első, akkor kicseréljük őket
                val csere =number2
                number2=number1
                number1=csere
            }
            //második szám nem lenne szabad h nagyobb legyen 999-nél
            if(number2>999&&(filteredrange[0]<999)){//a minimum és 999 között generáljunk, de csak akkor, ha a minimum kisebb, mint 999
                number2 = (filteredrange[0]..999).random()
            }

            if(uiState.value.extra=="multiplicationwithsingledigit"){
                number2=(2..9).random()
            }
            if(uiState.value.extra=="multiplicationwithdoubledigit"){
                number2=(10..99).random()
            }
        }

        if(operation==":"){//ha osztás, akkor max négyjegyű legyen
            number1 = (4..4000).random()
            var min = min(number1,99)//második szám pedig max kétjegyű
            number2 = (2..min).random()

            when (uiState.value.extra){
                "div_2_1"->{
                    number1 = (10..99).random()
                    var min = min(number1,9)
                    number2 = (2..min).random()
                }
                "div_3_1"->{
                    number1 = (100..999).random()
                    var min = min(number1,9)
                    number2 = (2..min).random()
                }
                "div_4_1"->{
                    number1 = (1000..9999).random()
                    var min = min(number1,9)
                    number2 = (2..min).random()
                }
                "div_2_2"->{
                    number1 = (10..99).random()
                    var min = min(number1,99)
                    number2 = (10..min).random()
                }
                "div_3_2"->{
                    number1 = (100..999).random()
                    var min = min(number1,99)
                    number2 = (10..min).random()
                }
                "div_4_2"->{
                    number1 = (1000..9999).random()
                    var min = min(number1,99)
                    number2 = (10..min).random()
                }
            }
        }





        var solution = evalirasbeli(number1, number2, operation.replace("*".toMultiplicationdot(), "*").replace(":","/"))

        if(uiState.value.számkörenabled){ //ha egy bizonyos számkörben dolgozunk, akkor addig generáljuk a feladatokat, amíg nem kapunk olyat, ami megfelel számunkra
            while (solution !in uiState.value.számkör){
                number1 = (filteredrange[0]..filteredrange.last()).random()
                number2 = (filteredrange[0]..filteredrange.last()).random()
                operation = try {
                    _uiState.value.operations.random()
                } catch (e: Exception) {
                    "+"//ha nem választott ki egyet sem, akkor legyen összeadás
                }
                solution = evalirasbeli(number1, number2, operation.replace("*".toMultiplicationdot(), "*").replace(":","/"))

            }
        }



        //  val partialResults = calculatePartialResults(number1, number2, operation)
        val SolutionList =
            getSolutionList(number1, number2, operation, /*partialResults,*/ solution = solution)
        var rowLength = (SolutionList.maxByOrNull { it.size }?.size
            ?: 1)//maxOf(number1.toString().length, number2.toString().length)+1


        val indexesOfUnknownCells = when (operation) {
            "+" -> {
                SolutionList[2].mapIndexed { index, s ->
                    Cellindex(2,index)
                }.toSet()

            }

            "-" ->{ SolutionList[2].mapIndexed { index, s ->
                Cellindex(2,index)}.toSet() }
            "*".toMultiplicationdot() ->{
//az egyes indextől kezdve minden sor minden egyes celláját hozzáadni a halmazhoz
                multiplicationUnknowns(SolutionList)
            }
            ":" -> { divisionUnknowns(SolutionList,number1,number2) }
            else -> { setOf() }//üreshalmaz
        }


        val displayList = SolutionList.mapIndexed { i, strings ->
            strings.mapIndexed { j, s ->
                if (s != "+"&& Cellindex(i,j) in indexesOfUnknownCells) "" else s//ha a string nem egy összeadásjel ÉS benne van az ismeretlen cellák halmazában, akkor üres, különben  saját maga
            }
        }//itt készítjük az üres sorokkal a displaylistet

        /*
        indexesOfUnknownCells.forEachIndexed { index, i ->
            var newlist =displayList //displayList[indexesOfUnknownCells[index]].map { if (it != "+") "" else it }
            displayList[indexesOfUnknownCells[index]] = newlist
        }*/





        //  println("$tag solutionlist =${SolutionList}")
        //  println("$tag displaylist =${displayList}")


        _uiState.update {
            it.copy(
                number1 = number1,
                number2 = number2,
                rowLength = rowLength,
                operation = operation,
                //   partialResultList = partialResults,
                displayList = displayList,
                solutionList = SolutionList,
                unknownCellIndex = indexesOfUnknownCells,
                solution = solution.toString()
            )
        }
    }

    private fun multiplicationUnknowns(SolutionList:MutableList<List<String>>):Set<Cellindex>{
        var celliendexes : MutableSet<Cellindex> = mutableSetOf()
        for (i in 1..SolutionList.size-1){//az első sor kihagyásával mindegyik
            for (j in 0..SolutionList[i].size-1){//a maradék sorok minden egyes eleme
                var newindex = Cellindex(i,j)
                celliendexes.add(newindex)
            }
        }
        return celliendexes as Set<Cellindex>
    }

    private fun divisionUnknowns(SolutionList:MutableList<List<String>>, osztando:Int,oszto:Int):Set<Cellindex>{//number1/number2 ismert
        var celliendexes : MutableSet<Cellindex> = mutableSetOf()
        var row1len = oszto.toString().length+osztando.toString().length+2 //két szám hossza, meg az egyenlőségjel meg a kettőspont
        //attól az indextől kezdve adjuk hozzá a cellindex-eket a halmazhoz amilyen hosszú volt a feladat eleje
        //először külön hozzáadjuk az első sort
        SolutionList[0].forEachIndexed { index, s ->
            if(index>=row1len){//mindegyik olyan indexre amelyik nagyobb egyenlő a hosszal, kerüljön hozzáadásra, azaz legyen ismeretlen
                var newindex = Cellindex(0,index)
                celliendexes.add(newindex)
            }
        }
        //a maradék pedig működjön úgy, mint a szorzásnál
        celliendexes +=multiplicationUnknowns(SolutionList)
        return celliendexes as Set<Cellindex>
    }

    suspend fun checkSolution() {
        val isCorrect =
            uiState.value.solutionList == uiState.value.displayList//a displaylistet módosíthatja a diák
        //   println("$tag iscorrect = $isCorrect")
        //   println("$tag displaylist =${uiState.value.displayList}")
        //   println("$tag solutionlist =${uiState.value.solutionList}")

        _uiState.update {
            it.copy(
                isCorrect = isCorrect,
                numberofsolved = it.numberofsolved + 1,
                showsolution = it.solution
            )
        }
        var htmlspace = "&nbsp;"
        val megoldókulcs = negyzethaloToText(uiState.value.solutionList,uiState.value.operation).replace(
            " ",
            htmlspace
        ) + "<br>"//ez a replace a HTML-es megjelenítéshez kell. így nem fogja automatikusan összevonni a feleslegesnek vélt szóközöket.
        val userinput = "<br>" + negyzethaloToText(uiState.value.displayList,uiState.value.operation).replace(" ", "&nbsp;")

            LaTexLogWriteDesktop(
            helyesMO = isCorrect,
            displayedTask = megoldókulcs,
            showSolutionIfUserWrong = "",//ez úgy jött ki, hogy üres lesz
            userinput = userinput,
            activityType = tag,
                taskLaTexWrap = false,
//            userDocRef = userDocRef,
//            modulID = 15//todo kiszámolni, hogy ez hanyadik modul

        )

        generateNewProblem()

    }

    private fun negyzethaloToText(solutionList: List<List<String>>, operation: String): String {
        // Először minden belső listát egyetlen stringgé alakítunk

        val concatenatedStrings = solutionList.map { innerList ->
            innerList.map {
                if (it == "") {
                    " "
                } else it
            }.joinToString("") // Belső lista elemeinek összefűzése
            //az üres stringek helyett szóközöket írunk
        }
        // Megkeressük a leghosszabb sort
        //─ (U+2500)
        val longestLine = concatenatedStrings.maxByOrNull { it.length } ?: ""
        val dashLine =
            "─".repeat(longestLine.length) // Létrehozunk egy sort, ami annyi darab - karakterből áll

        // Az összesített stringeket egyetlen stringgé alakítjuk, sortörésekkel elválasztva
        val result = concatenatedStrings.toMutableList()
        if (result.isNotEmpty()&& operation!=":") {
            result.add(
                result.size - 1,
                dashLine
            ) // Beszúrjuk a kötőjelekből álló sort az utolsó sor elé
        }

        return result.joinToString("<br>") // Sortörés elválasztóként
    }


    fun updateDisplayList(
        rowindex: Int,
        charindex: Int,
        char: String
    ) {
        // Get the current display list from the UI state
        val displayList = _uiState.value.displayList.toMutableList()

        var newLine = displayList[rowindex].toMutableList()
        var newChar = try {
            char.last().toString()//.digitToInt()
        } catch (e: Exception) {
            ""
        }
        newLine[charindex] = newChar.toString()
        displayList[rowindex] = newLine

        // Update the UI state with the modified display list
        _uiState.update {
            it.copy(displayList = displayList)
        }

    }


    private fun evalirasbeli(number1: Int, number2: Int, operation: String): Int {
        if(operation!=":"&&operation !="/") {
            val feladat = "$number1 $operation $number2"
            val solution = eval(feladat).toString().toDouble()
            return solution.toInt() //evaluateExpression(feladat).toInt()//ennek egész számnak kell lennie. ha tört szám, akkor még a maradékot kell majd visszaadni}
        }
        else{
            return (number1*1.0/number2).toInt()
        }
    }

    private fun getSolutionList(
        number1: Int,
        number2: Int,
        operation: String, /*partialResults: List<String>,*/
        solution: Int
    ): MutableList<List<String>> {
        val d =
            number1.toString().length - number2.toString().length//todo n számból álló feladat esetén általánosítani
        val extra = mutableListOf<String>()
        if (d > 0) {
            repeat(d) {
                extra.add("")
            }
        }

        val sumlist = mutableListOf(number1.toString().map { it.toString() },
            listOf("+") + extra + number2.toString().map { it.toString() },
            solution.toString().map { it.toString() })
        val subtractlist = mutableListOf(number1.toString().map { it.toString() },
            listOf("-") + extra + number2.toString().map { it.toString() },
            solution.toString().map { it.toString() })


        val multiplicationlist = (mutableListOf(
            "$number1${"*".toMultiplicationdot()}$number2".toString()
                .map { it.toString() }) + getmultiplicationLines(
            number1,
            number2,
            shift = uiState.value.shift
        )).toMutableList()

        var divisionlist = mutableListOf<List<String>>()
        if(operation==":"){
            divisionlist= (listOf("$number1:$number2=${(number1*1.0/number2).toInt()}".map { it.toString() }) +getdivisionlist(number1,number2)).toMutableList()
        }


        val indexesOfUnknownLines = when (operation) {//todo ez csak egyszer legyen benne a kódban
            "+" -> listOf(2)
            "-" -> listOf(2)
            "*".toMultiplicationdot() -> (1..multiplicationlist.size - 1).toList()
            ":" -> (1..divisionlist.size - 1).toList()
            else -> listOf(2)//default case should not be reached
        }


        var solutionlist = when (operation) {
            "+" -> {
                sumlist
            }

            "-" -> {
                subtractlist
            }

            "*".toMultiplicationdot() -> {
                multiplicationlist
            }
            ":" -> {
                divisionlist
            }
            else -> mutableListOf()//default case should not be reached
        }
        var rowLength = (solutionlist.maxByOrNull { it.size }?.size ?: 1)//leghosszabb sor hossza lesz a sorhossz
        val firstlistextras = List(rowLength - solutionlist[0].size) { "" }
        solutionlist[0] = firstlistextras + solutionlist[0].map { it }


        if(operation!=":") {
            indexesOfUnknownLines.forEachIndexed { index, i ->//a feladat készítése során balról is kipótoljuk annyi üres stringgel, ahányra szükségünk van
                val extraChars = mutableListOf<String>()
                repeat(rowLength - solutionlist[i].size) {//előröl is ki kell pótolni *üres* stringekkel. vesszük a sorhosszt, és az i. megoldássor hosszának különbségét
                    extraChars.add("")
                }
                solutionlist[i] = extraChars + solutionlist[i].map { it }
            }
        }else{//ha osztás akkor jobbról kell kipótolni
            indexesOfUnknownLines.forEachIndexed { index, i ->//a feladat készítése során jobbról is kipótoljuk annyi üres stringgel, ahányra szükségünk van
                val extraChars = mutableListOf<String>()
                repeat(rowLength - solutionlist[i].size) {//előröl is ki kell pótolni *üres* stringekkel. vesszük a sorhosszt, és az i. megoldássor hosszának különbségét
                    extraChars.add("")
                }
                solutionlist[i] =  solutionlist[i].map { it }+extraChars
            }
        }

        return solutionlist


    }

    private fun getdivisionlist(osztando: Int, oszto: Int): MutableList<List<String>> {
        var returnlist = mutableListOf<List<String>>()
        var eredeti =osztando.toString()
        var osztando:Int=osztando
        var partialresult:Int
        var partialremainder:Int
        var osztandoS =""
        var result=""
        eredeti .forEachIndexed { index, c ->
            osztandoS += c.toString()

            osztando = osztandoS.toInt()
            if(osztando>=oszto){//ha már hoztunk le valamit, akkor vizsgáljuk meg, hogy így osztható-e az osztóval
                //ha osztható, akkor osszuk el
                var partialresult = osztando/oszto
                var partialremainder =osztando%oszto
                // println("partialresult = $partialresult")
                result+=partialresult
                osztando = partialremainder
                osztandoS = osztando.toString()

                var padding = "A".repeat(index-(osztandoS.length-1))//annyi paddinget rakunk, ahanyadik indexnél tartunk. az első szám a nulladik. a paddingből le kell venni. Ha egyjegyű a szám, akkor nullát, ha kétjegyű, akkor egyet
                if(index+1< eredeti.length) {//ha van még következő karakter, akkor hozzunk le annyit amennyit még kell. ha nincs, akkor nyilván kész vagyunk
                    var lehozzuk =""
                    var i=1
                    while(index+i<eredeti.length&&(osztandoS+lehozzuk).toInt()<oszto){//addig hozzuk le a következő számokat, amíg nem kapunk az osztóval osztható számot, vagy el nem fogynak
                        // println("lehozás: index=$index, i=$i, új szám: ${eredeti[index+i]}")
                        lehozzuk +=eredeti[index+i]
                        i++
                    }
                    returnlist.add("${padding}${osztandoS}${lehozzuk}".map { it.toString().replace("A","") })
                    // println("${padding}${osztandoS}${eredeti[index+1]}")
                } else {
                    returnlist.add("${padding}${osztandoS}".map { it.toString() .replace("A","")})
                    //println("${padding}${osztandoS}")
                }

            }
        }
        return returnlist
    }


    fun getmultiplicationLines(
        number1: Int,
        number2: Int,
        shift: String = "left"
    ): List<List<String>> {//az első sor egyszerű, a második sortól számoljuk ki
        //nevezzük az első számot szorzandónak, a második számot pedig szorzónak
        //a sorok a következőek: szorzó első számjegye*szorzandó, szorzó második számjegye*szorzandó... kiegészítve ennyi üres stringgel: "1 a szorzásjel + szorzó hossza+szorzó adott számjegyének indexe, ami nullával fog kezdődni
        var szorzóJegyei = number2.toString().map { it.digitToInt() }
        // println("$tag number1 =$number1")
        // println("$tag number2 =$number2")
        // println("$tag szorzóJegyei = $szorzóJegyei")
        val sorok = mutableListOf<List<String>>()
        if ( shift=="left"){
            szorzóJegyei=szorzóJegyei.reversed()//ha balra toljuk, akkor meg kell fordítani. ha jobbra toljuk a sorokat, akkor nem kell, mert akkor a legnagyobb helyiértékkel kezdünk
        }

//itt is kezelni kell hogy jobbra-balra toljuk
        var üresStringek = listOf<String>()

        szorzóJegyei.forEachIndexed { index, számjegy ->
            val részeredmény = számjegy * number1
            val részeredménystring = if (részeredmény == 0) {
                "0".repeat(number1.toString().length)
            } else {
                részeredmény.toString()
            }

            //println("$tag $számjegy*$number1 = $részeredmény")
            var indexshift = if(shift =="left") index else -index
            üresStringek = List(indexshift + 1 + number2.toString().length) { "" }
           // println("$tag üresStringek = $üresStringek")
          //  println("$tag indexshift = $indexshift")
            var részeredményStringlistája = részeredménystring.map { it.toString() }
            // if(balratolás) részeredményStringeklistái= részeredményStringeklistái.reversed()
            val sor =  részeredményStringlistája + üresStringek//itt kezeljük majd a jobbra és balra tolást
          //  println("$tag új sor = $sor")
            sorok.add(sor)
        }
        var plussignpadding = listOf<String>()

        if (number2 >= 10) {
            if(shift=="right"){
                plussignpadding = List(sorok[0].size-sorok.last().size) { "" }// ha jobbra toljuk a sort, akkor  máshogy kellene beszúrni a + jelet, mégpedig a + jel és a maga a sor közé kellene beszúrni pár üres stringet első sor hossza - utolsó sor hossza
            }
            sorok[sorok.size - 1] = listOf("+") +plussignpadding+ sorok.last()
        }


        val végeredmény = number1 * number2
        var végeredménysor = végeredmény.toString().map { it.toString() }
        var végeredményIndentálás = List(1 + number2.toString().length) { "" } //ennyi ha balra toljuk a szorzást
        if (shift=="right"){
            /*  val l = sorok.last().size//utolsó sor hossza
              val k = number1*szorzóJegyei.last().toString().length//utolsó szám hossza
              val n = l-k-plussignpadding.size//utolsó számot ennyit indentáltuk, de a plus paddinget ne számoljuk bele*/
            végeredményIndentálás = üresStringek//pont annyi indentálás jobbra mint a legutóbbi sornak
        }
        végeredménysor =  listOf("") + végeredménysor + végeredményIndentálás //else listOf("") + végeredményIndentálás+ végeredménysor  //balra-jobbra tolás. ha több sorból állt a számolás, akkor hozzáfűzünk egy + jelet, ami miatt eggyel hosszabb lesz a sor balról. az az üres string
        if (number2 >= 10) sorok.add(végeredménysor) //ha egyjegyű szám a szorzó, akkor csak egy sorunk lesz, amit nem kell összeadni a többivel
        // println("$tag végeredmény = $végeredmény")
        // println("$tag végeredménysor = $végeredménysor")
        return sorok

    }

    fun setOperations(operations: Set<String>) {
        _uiState.update { it.copy(operations = operations) }
        generateNewProblem()
    }

    fun addOperation(operation: String) {
        _uiState.update { it.copy(operations = it.operations + operation) }
    }

    fun removeOperation(operation: String) {
        _uiState.update { it.copy(operations = it.operations - operation) }
    }

    fun updateNumberRangeStart(n: String) {
        _uiState.update { it.copy(numberRangeStartS = n.toString()) }
        if (n != "" && n.toInt() < uiState.value.numberRange.last) {
            _uiState.update { it.copy(numberRange = (n.toInt()..it.numberRange.last)) }
        }
    }

    fun updateNumberRangeEnd(n: String) {
        _uiState.update { it.copy(numberRangeEndS = n.toString()) }

        if (n != "" && n.toInt() > uiState.value.numberRange.first) _uiState.update {
            it.copy(
                numberRange = (it.numberRange.first..n.toInt())
            )
        }
    }
    fun updateszámkör(számkör:IntRange){
        _uiState.update { it.copy(számkör=számkör) }
    }

    fun setszámkörenabled(enabled:Boolean){
        _uiState.update { it.copy(számkörenabled=enabled) }
    }

    fun setExtra(extra: String){
        _uiState.update { it.copy(extra=extra) }
    }

    fun updateShift(shift: String) {
        _uiState.update { it.copy(shift = shift) }
        // Regenerate problem or update logic based on the new shift
    }
    /*  private fun calculatePartialResults(number1: Int, number2: Int, operation: String): List<String> {
          return when (operation) {
              "+" -> listOf("")
              "-" -> listOf("")
              "*" -> {
                  listOf("")
              }
              "/" -> {
                  listOf("")
              }
              else -> emptyList()//default case should not be reached
          }
      }*/
}

@Composable
fun IrasbeliScreen(

    viewModel: IrasbeliViewModel = androidx.lifecycle.viewmodel.compose.viewModel {
        IrasbeliViewModel(
        )

    },

    navcontroller: NavHostController,

    /*navController: NavHostController,*/
    tag: String = "IrasbeliMuveletek",
    settingstype: Int,
    ratios: ratios

) {
    val uiState by viewModel.uiState.collectAsState()
    var tasklistshown = remember{ mutableStateOf(false) }
val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(Unit) {
        if(settingstype==1){//ha az alsó tagozatos írásbeli műveleteket nyitottuk meg, akkor legyen látható a feladattípus lista egyből
            tasklistshown.value=true
        }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {



        // Spacer(modifier = Modifier.height(16.dp))

        // Display the grid of OutlinedTextFields
        DisplayGrid(
            uiState.displayList,
            uiState.rowLength,
            uiState.operation,
            viewModel::updateDisplayList,
            uiState.unknownCellIndex,
            ratios
        )

        //  Spacer(modifier = Modifier.height(16.dp))

        // Button to generate a new problem
        /*  Button(onClick = { viewModel.generateNewProblem() }) {
              Text("Generate New Problem")
          }*/
        Spacer(Modifier.width(16.dp))

        Row(
            Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Button(onClick = { coroutineScope.launch { viewModel.checkSolution() } }) {
                Text(stringResource(Res.string.Check))
            }
            Spacer(Modifier.width(8.dp))
            simpleLogButton(navController =navcontroller ,  tag)

        }

        if(settingstype==0)    generatorSettings {
            settingListItem(
                content = {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        val jetbrainsmonofamily = FontFamily(
                            org.jetbrains.compose.resources.Font(Res.font.jetbrainsmonoregular)//ezt kell majd valahogy használni
                        )
                        val operations = listOf("+", "-", "*".toMultiplicationdot(),":")
                        operations.forEach { operation ->
                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(operation, fontSize = uifont, fontFamily = jetbrainsmonofamily)
                                Checkbox(
                                    checked = operation in uiState.operations,
                                    onCheckedChange = {
                                        if (it) {
                                            viewModel.addOperation(operation)
                                        }
                                        if (!it) {
                                            viewModel.removeOperation(operation)

                                        }
                                    }
                                )
                            }
                        }

                    }
                },
                alpha = alpha1,
            )

            settingListItem(
                content = {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally
                    ){
                        Text(
                            text = stringResource(Res.string.D),
                            modifier = Modifier.padding(8.dp),
                            fontSize = uifont
                        )
                        Row(
                            horizontalArrangement = Arrangement.Center,
                            verticalAlignment = Alignment.CenterVertically
                        ){
                            paramField(
                                value = uiState.numberRangeStartS,
                                enterText = { it ->
                                    viewModel.updateNumberRangeStart(it)
                                },
                                stringResource( Res.string.from),
                            )
                            Spacer(Modifier.width(8.dp))
                            paramField(
                                value = uiState.numberRangeEndS,
                                enterText = { it ->
                                    viewModel.updateNumberRangeEnd(it)
                                },
                                stringResource(Res.string.to)
                            )
                        }
                    }

                },
                alpha2
            )
            settingListItem(

                content = {
                    MultiplicationShiftSegmentedButton { selectedShift ->
                        // Handle the shift change, e.g., update the ViewModel
                        viewModel.updateShift(selectedShift)
                    }
                },
                alpha1
            )

            settingListItem(content = {
                Column(
                    verticalArrangement = Arrangement.Center,
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        stringResource( Res.string.customrange),
                        fontSize = uifont/*,modifier=Modifier.fillMaxWidth(), textAlign = TextAlign.Center*/
                    )
                    val buttonspacer = Modifier.width(5.dp)
                    //todo ide az egyéni intervallumok
                    Row(/*horizontalArrangement = Arrangement.SpaceAround,modifier= Modifier.fillMaxWidth()*/) {
                        Button(onClick = {
                            viewModel.updateNumberRangeStart("1")
                            viewModel.updateNumberRangeEnd("20")
                        }) {
                            Text("1..20")
                        }
                        Spacer(modifier = buttonspacer)
                        Button(onClick = {
                            viewModel.updateNumberRangeStart("1")
                            viewModel.updateNumberRangeEnd("50")
                        }) {
                            Text("1..50")
                        }
                        Spacer(modifier = buttonspacer)



                        Button(onClick = {
                            viewModel.updateNumberRangeStart("1")
                            viewModel.updateNumberRangeEnd("200")
                        }) {
                            Text("1..200")
                        }
                    }
                    Row(/*horizontalArrangement = Arrangement.SpaceAround,modifier=  Modifier.fillMaxWidth()*/) {
                        Button(onClick = {
                            viewModel.updateNumberRangeStart("10")
                            viewModel.updateNumberRangeEnd("500")
                        }) {
                            Text("10..500")
                        }
                        Spacer(modifier = buttonspacer)

                        Button(onClick = {
                            viewModel.updateNumberRangeStart("500")
                            viewModel.updateNumberRangeEnd("1000")
                        }) {
                            Text("500..1000")
                        }

                    }
                }
            }, alpha = alpha1)


        }
        if(settingstype==1) {
            Spacer(Modifier.size(60.dp))
            Button(
                onClick = {
                    tasklistshown.value=!tasklistshown.value
                }
            ){
                Text("feladattípus választás")
            }
        }
    }

    if(settingstype==1) {
        if (tasklistshown.value) {
            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
                feladattipuslistabuttons(viewModel, tasklistshown)
            }
        }
    }

    backButton(navcontroller)
    responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.showsolution)
}

@Composable
fun feladattipuslistabuttons(
    vm: IrasbeliViewModel,
    tasklistshown: MutableState<Boolean>
) {
    Surface(
        modifier = Modifier.fillMaxSize(),
        color = MaterialTheme.colorScheme.background,

        ) {
        Column(modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState()), verticalArrangement =Arrangement.Center, horizontalAlignment =Alignment.CenterHorizontally ) {
            // Addition buttons
            Button(onClick = {
                vm.setOperations(setOf("+"))
                vm.setszámkörenabled(true)
                vm.updateszámkör(1..10)
                vm.updateNumberRangeStart("1")
                vm.updateNumberRangeEnd("10")
                tasklistshown.value=false
                vm.generateNewProblem()
            }) {
                Text("Összeadás tízes számkörben")
            }

            Button(onClick = {
                vm.setOperations(setOf("+"))

                vm.setszámkörenabled(true)
                vm.updateszámkör(1..20)
                vm.updateNumberRangeStart("1")
                vm.updateNumberRangeEnd("20")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Összeadás húszas számkörben")
            }

            Button(onClick = {
                vm.setOperations(setOf("+"))
                vm.setszámkörenabled(true)
                vm.updateszámkör(1..100)
                vm.updateNumberRangeStart("1")
                vm.updateNumberRangeEnd("100")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Összeadás százas számkörben")
            }

            Button(onClick = {
                vm.setOperations(setOf("+"))

                vm.setszámkörenabled(true)
                vm.updateszámkör(1..1000)
                vm.updateNumberRangeStart("1")
                vm.updateNumberRangeEnd("1000")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Összeadás ezres számkörben")
            }

            // Subtraction buttons
            Button(onClick = {
                vm.setOperations(setOf("-"))

                vm.setszámkörenabled(true)
                vm.updateszámkör(1..10)
                vm.updateNumberRangeStart("1")
                vm.updateNumberRangeEnd("10")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Kivonás tízes számkörben")
            }

            Button(onClick = {
                vm.setOperations(setOf("-"))

                vm.setszámkörenabled(true)
                vm.updateszámkör(1..20)
                vm.updateNumberRangeStart("1")
                vm.updateNumberRangeEnd("20")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Kivonás húszas számkörben")
            }

            Button(onClick = {
                vm.setOperations(setOf("-"))

                vm.setszámkörenabled(true)
                vm.updateszámkör(1..100)
                vm.updateNumberRangeStart("1")
                vm.updateNumberRangeEnd("100")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Kivonás százas számkörben")
            }

            Button(onClick = {
                vm.setOperations(setOf("-"))

                vm.setszámkörenabled(true)
                vm.updateszámkör(1..1000)
                vm.updateNumberRangeStart("1")
                vm.updateNumberRangeEnd("1000")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Kivonás ezres számkörben")
            }

            Button(onClick = {
                vm.setszámkörenabled(false)

                vm.setOperations(setOf("*".toMultiplicationdot()))
                vm.updateNumberRangeStart("2")
                vm.updateNumberRangeEnd("99")
                vm.setExtra("multiplicationwithsingledigit")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Szorzás egyjegyűvel")
            }

            Button(onClick = {
                vm.setszámkörenabled(false)

                vm.setOperations(setOf("*".toMultiplicationdot()))
                vm.updateNumberRangeStart("2")
                vm.updateNumberRangeEnd("400")
                vm.setExtra("multiplicationwithdoubledigit")
                vm.updateShift("left")
                tasklistshown.value=false
                vm.generateNewProblem()

            }) {
                Text("Szorzás kétjegyűvel (sorok balra tolásával)")
            }


            Button(onClick = {
                vm.setszámkörenabled(false)
                vm.setOperations(setOf("*".toMultiplicationdot()))
                vm.updateNumberRangeStart("2")
                vm.updateNumberRangeEnd("400")
                vm.setExtra("multiplicationwithdoubledigit")
                vm.updateShift("right")
                tasklistshown.value=false
                vm.generateNewProblem()
            }) {
                Text("Szorzás kétjegyűvel (sorok jobbra tolásával)")
            }

            Button(onClick = {
                vm.setOperations(setOf(":"))
                vm.setExtra("div_2_1")
                tasklistshown.value=false
                vm.generateNewProblem()
            }) {
                Text("Kétjegyű szám osztása egyjegyűvel")
            }
            Button(onClick = {
                vm.setOperations(setOf(":"))
                vm.setExtra("div_3_1")
                tasklistshown.value=false
                vm.generateNewProblem()
            }) {
                Text("Háromjegyű szám osztása egyjegyűvel")
            }
            Button(onClick = {
                vm.setOperations(setOf(":"))
                vm.setExtra("div_4_1")
                tasklistshown.value=false
                vm.generateNewProblem()
            }) {
                Text("Négyjegyű szám osztása egyjegyűvel")
            }
            Button(onClick = {
                vm.setOperations(setOf(":"))
                vm.setExtra("div_2_2")
                tasklistshown.value=false
                vm.generateNewProblem()
            }) {
                Text("Kétjegyű szám osztása kétjegyűvel")
            }
            Button(onClick = {
                vm.setOperations(setOf(":"))
                vm.setExtra("div_3_2")
                tasklistshown.value=false
                vm.generateNewProblem()
            }) {
                Text("Háromjegyű szám osztása kétjegyűvel")
            }
            Button(onClick = {
                vm.setOperations(setOf(":"))
                vm.setExtra("div_4_2")
                tasklistshown.value=false
                vm.generateNewProblem()
            }) {
                Text("Négyjegyű szám osztása kétjegyűvel")
            }


        }

    }
}



@Composable
fun MultiplicationShiftSegmentedButton(
    onShiftChange: (String) -> Unit
) {
    val options = listOf("Balra toljuk a szorzást", "Jobbra toljuk a szorzást")
    val optionstoset = listOf("left", "right")
    var selectedOptionIndex by remember { mutableStateOf(0) }

    SegmentedButtons(
        modifier = Modifier
            .fillMaxWidth()
            .padding(8.dp),
        shape = RoundedCornerShape(8.dp),
        colors = SegmentedButtonsDefaults.colors()
    ) {
        options.forEachIndexed { index, option ->
            SegmentedButtonItem(
                selected = selectedOptionIndex == index,
                onClick = {
                    selectedOptionIndex = index
                    onShiftChange(optionstoset[index])
                },
                label = { Text(option) }
            )
        }
    }
}

@Composable
fun DisplayGrid(
    displayList: List<List<String>>,
    rowLength: Int,
    operation: String,
    updateChar: (Int, Int, String) -> Unit = { _, _, _ -> },
    unknownCellindexes: Set<Cellindex>,
    ratios: ratios
) {
    //  val len =  displayList.maxOf { it.length }
    Column(
        modifier=Modifier.horizontalScroll(rememberScrollState())
    ) {
        val size =when(ratios.widthtype){
            widthtype.small->50f
            widthtype.medium->75f
            widthtype.large->100f
        }
        val fontsize =
            when(ratios.widthtype){
                widthtype.small->18f
                widthtype.medium->30f
                widthtype.large->50f
            }
        var displayreverse = displayList.map { it/*.reversed() */}
        // if (operation==":") displayreverse = displayList
        displayreverse.forEachIndexed { i, string ->//a string most már (jó  esetben) egy karakterből álló stringek listája
            Row(

            ) {
                for (j in   0..rowLength - 1) {
                    val value = try {
                        displayreverse[i][j].toString()
                    } catch (e: Exception) {
                        ""
                    }

                    OutlinedTextField(
                        value = value,
                        onValueChange = {
                            // println("IrasbeliViewModel,beírt érték=$it")
                            updateChar(
                                i,
                                j,
                                it
                            )//ha pont olyan hosszú a négyzetháló, hogy kiférjen a feladat, akkor -1. ha annál hosszabb, akkor annyival többet kell az indexen igazítani
                        },
                        modifier = Modifier.size(size.dp),
                        enabled = Cellindex(i,j) in unknownCellindexes,
                        textStyle = TextStyle(fontSize = fontsize.sp)
                    )
                }
            }
        }
    }

}

data class Cellindex(
    val sor :Int,//i, 0-tól számozzuk
    val oszlop: Int//j
)


fun String.toMultiplicationdot(): String {
    return "·"
}