package matekit.matekit

//import androidx.compose.ui.graphics.vector.ImageVector
//import androidx.compose.ui.res.vectorResource
//import androidx.compose.ui.text.input.KeyboardType

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AccountCircle
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
//import androidx.datastore.core.DataStore
//import androidx.datastore.preferences.core.Preferences
import androidx.navigation.NavHostController
import androidx.navigation.NavType
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.currentBackStackEntryAsState
import androidx.navigation.compose.rememberNavController
import androidx.navigation.navArgument
import matekit.matekit.games.MagicSquare
import matekit.matekit.games.Szogbecsles
import matekit.matekit.games.pyramidscreen
import matekit.matekit.games.szamkiraly.SzamkiralyHostUI
//import matekit.matekit.games.szamkiraly.SzamkiralyRoute
import matekit.matekit.taskgenerators.AlapmuveletekScreen
import matekit.matekit.taskgenerators.AlgebraiAzonossagok
import matekit.matekit.taskgenerators.AlgebraiAzonossagokDolgozat
import matekit.matekit.taskgenerators.Atvaltasok
import matekit.matekit.taskgenerators.ConvertNumberBase
import matekit.matekit.taskgenerators.CoordinateSystemIntroduction
import matekit.matekit.taskgenerators.EquationGeneratorScreen
import matekit.matekit.taskgenerators.EquationSystemScreen
import matekit.matekit.taskgenerators.FractionScreen
import matekit.matekit.taskgenerators.FunctionAnalysis
//import matekit.matekit.taskgenerators.FractionScreen
//import matekit.matekit.taskgenerators.FunctionAnalysis
import matekit.matekit.taskgenerators.GeometriaKockasLaponUI
import matekit.matekit.taskgenerators.IrasbeliScreen
import matekit.matekit.taskgenerators.KombinatorikaScreen
import matekit.matekit.taskgenerators.PrimeFactorScreen
import matekit.matekit.taskgenerators.ProportionalityScreen
import matekit.matekit.taskgenerators.RomaiSzamScreen
import matekit.matekit.taskgenerators.SorozatokScreen
import matekit.matekit.taskgenerators.SzamolasNegativSzamokkalUI
import matekit.matekit.taskgenerators.SzazalekScreen
import matekit.matekit.taskgenerators.SzovegesUI
import matekit.matekit.taskgenerators.functionReadingScreen
import matekit.matekit.taskgenerators.tizhatvanyUI
import matekit.ui.FloatingKeyboard
import matekit.ui.ratios
import matekit.ui.testarea
import matekit.ui.theme.matekitcolor
import matekit.ui.widthtype
import matekit_multiplatform.composeapp.generated.resources.BasicOperations
import matekit_multiplatform.composeapp.generated.resources.ConvertUnits
import matekit_multiplatform.composeapp.generated.resources.FunctionReading
import matekit_multiplatform.composeapp.generated.resources.GetToKnowTheCoordinateSystem
import matekit_multiplatform.composeapp.generated.resources.MathKing
import matekit_multiplatform.composeapp.generated.resources.PracticeSolvingEquationSystems
import matekit_multiplatform.composeapp.generated.resources.PracticingEquations
import matekit_multiplatform.composeapp.generated.resources.PractisingFractions
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.about
import matekit_multiplatform.composeapp.generated.resources.angle_estimation
import matekit_multiplatform.composeapp.generated.resources.close
import matekit_multiplatform.composeapp.generated.resources.exponentiation
import matekit_multiplatform.composeapp.generated.resources.games
import matekit_multiplatform.composeapp.generated.resources.help
import matekit_multiplatform.composeapp.generated.resources.logout
import matekit_multiplatform.composeapp.generated.resources.percentage
import matekit_multiplatform.composeapp.generated.resources.practiceRomanNumerals
import matekit_multiplatform.composeapp.generated.resources.primefactors
import matekit_multiplatform.composeapp.generated.resources.proportionality
import matekit_multiplatform.composeapp.generated.resources.settings
import matekit_multiplatform.composeapp.generated.resources.taskgenerators
import matekit_multiplatform.composeapp.generated.resources.title_activity_algebrai_azonossagok
import matekit_multiplatform.composeapp.generated.resources.title_activity_algebrai_azonossagok_dolgozat
import matekit_multiplatform.composeapp.generated.resources.title_activity_function_analysis
import matekit_multiplatform.composeapp.generated.resources.title_activity_geometria_kockas_lapon
import matekit_multiplatform.composeapp.generated.resources.title_activity_irasbeli_muveletek
import matekit_multiplatform.composeapp.generated.resources.title_activity_irasbeli_muveletek_simple
import matekit_multiplatform.composeapp.generated.resources.title_activity_kombinatorika
import matekit_multiplatform.composeapp.generated.resources.title_activity_magic_square
import matekit_multiplatform.composeapp.generated.resources.title_activity_number_pyramid
import matekit_multiplatform.composeapp.generated.resources.title_activity_settings
import matekit_multiplatform.composeapp.generated.resources.title_activity_sorozatok
import matekit_multiplatform.composeapp.generated.resources.title_activity_szamolas_negativ_szamokkal
import matekit_multiplatform.composeapp.generated.resources.title_activity_szoveges_feladatok
import matekit_multiplatform.composeapp.generated.resources.title_activity_tizhatvany
import matekit_multiplatform.composeapp.generated.resources.unkp
import matekit_multiplatform.composeapp.generated.resources.unkp_logo_04_resized
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun TopBar(canNavigateBack: Boolean, onBackButtonPress: () -> Unit) {
    Row(
        modifier = Modifier.fillMaxWidth().height(50.dp).padding(15.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (canNavigateBack) {
            Text(text = "< Back", modifier = Modifier.clickable { onBackButtonPress() })
        } else {
            Spacer(modifier = Modifier)
        }

        Text(
            text = "App Title",
        )
        Spacer(modifier = Modifier)
    }
}


@Composable
fun mainmenu(ratios: ratios, orientation: MutableState<String>) {
    val navController = rememberNavController()
   // val platformname = getPlatform()
    // Define CompositionLocal for focused text field
    //val orientationChecker = OrientationChecker()
    //val orientation = orientationChecker.checkOrientation() //ez nem működött weben

   // println("Current Orientation: ${orientationChecker.checkOrientation()}")
// Define CompositionLocal for keyboard toggle state
    var focusedTextField = remember { mutableStateOf<MutableState<TextFieldValue>?>(null) }
    var keyboardToggle = remember { mutableStateOf(false) }



    Box(contentAlignment = Alignment.Center, modifier = Modifier.fillMaxSize()){


      //  webviewkmm("a^2+B^5=89")
        NavHost(navController = navController, startDestination = "mainmenu") {
            //  val orientationChecker = OrientationChecker(ScreenOrientationImpl())

            composable("mainmenu") {
                mainmenuscreen(navController,ratios)
            }
            composable(
                "pyramid_screen"
            ) {
                pyramidscreen(navController, focusedTextField)
            }
            composable("romai") {
                RomaiSzamScreen(navController, focusedTextField)
            }

            composable("testarea") {
                testarea(navController)
            }
            composable("fuggvenyleolvasas") {
                functionReadingScreen(navController, focusedTextField, ratios =ratios,  orientation=orientation)
            }
            composable("exponentiation") {
                Hatvanyozás(navController,ratios,orientation)
            }
            composable("alapm") {
                AlapmuveletekScreen(navController,ratios)
            }
            composable("egyenletrendszer") {
                EquationSystemScreen(navController,ratios)
            }
            composable("szazalek"){
                SzazalekScreen(navController)
            }
            composable("atvaltasok"){
                Atvaltasok(navController).content()
                //UnitConverterScreen(navController)
            }
            composable("geometriakockas"){
                GeometriaKockasLaponUI(navController,ratios=ratios,orientation=orientation)
            }
            composable("egyenletek"){
                EquationGeneratorScreen(navController,ratios)
            }
            composable("aranyossag"){
                ProportionalityScreen(navController)
            }

            composable("koordinata"){
                CoordinateSystemIntroduction(navController =  navController,ratios=ratios).coordinateSystemIntroduction(orientation)
            }
            composable("kombinatorika"){
                KombinatorikaScreen(navController)
            }
            composable("sorozatok"){
                SorozatokScreen(navController)
            }
            composable("primek"){
                PrimeFactorScreen(navController)
            }
            composable("tortek"){
                FractionScreen(navController,ratios)
            }
            composable("szamkiraly"){
                SzamkiralyHostUI(navController,ratios,orientation)
            }
            composable("szogbecsles"){
                Szogbecsles(navController).UI(ratios,orientation)
            }
            composable("fuggvenyanalizis"){
               FunctionAnalysis(navController= navController).UI()
            }
            composable("szamrendszerek"){
                ConvertNumberBase(navController = navController).UI()
            }
            composable("algebraiazonossagok"){
                AlgebraiAzonossagok(navController = navController).UI()
            }
            composable("algebraiazonossagokdolgozat"){
                AlgebraiAzonossagokDolgozat(navController = navController).UI()
            }
            composable("MagicSquare"){
                MagicSquare(navController).UI(ratios,orientation)
            }
            composable("settings"){
                SettingsMenu(navController)
            }

            composable("SzovegesFeladatok"){
                SzovegesUI(navController)
            }
            composable("IrasbeliMuveletek"){
                IrasbeliScreen(navcontroller=navController, settingstype = 0,ratios=ratios)
            }
            composable("IrasbeliMuveletekSimple"){
                IrasbeliScreen(navcontroller=navController, settingstype = 1,ratios=ratios)
            }
            composable("SzamolasNegativSzamokkal"){
                SzamolasNegativSzamokkalUI(navcontroller=navController,TAG="SzamolasNegativSzamokkal")
            }
            composable("tizhatvany"){
                tizhatvanyUI(navcontroller=navController,TAG="tizhatvany")
            }
          /* composable("szamkiralygame"){
                //implement here getting the settings
                MathKingGameScreen(gamesettings,navcontroller)
            }*/




            composable(
                "latexlog/{type}",
                arguments = listOf(navArgument("type") { type = NavType.StringType })
            ) { backStackEntry ->
                val type = backStackEntry.arguments?.getString("type")
                LaTeXLogUI(navController,type = type ?: "")
            }

            composable(
                "simplelog/{type}",
                arguments = listOf(navArgument("type") { type = NavType.StringType })
            ) { backStackEntry ->
                val type = backStackEntry.arguments?.getString("type")
                simpleLogUI(navController,type = type ?: "")
            }

        }
        }
        // Observe the current destination
        val currentBackStackEntry by navController.currentBackStackEntryAsState()
        val currentDestination by mutableStateOf(currentBackStackEntry?.destination?.route)
        //println("Current destination: $currentDestination")
        // Conditionally show the FloatingKeyboard
        if ((currentDestination == "pyramid_screen" || currentDestination == "equationsolving")&&false) {//todo
        //    println("Showing FloatingKeyboard")
            FloatingKeyboard(
                focusedTextField = focusedTextField.value,
                keyboardToggle = keyboardToggle,
            )
        }
        }





    // }



@OptIn(ExperimentalLayoutApi::class)
@Composable
fun mainmenuscreen(navController: NavHostController, ratios: ratios)

    {
  //  println("Locale.current.language: ${Locale.current.language}")

        val aboutRead = false//használni a shardprefst
        val name = "user"//todo ha van fiók, akkor foglalkozni vele
       // val buttonModifier = Modifier.fillMaxWidth()
        val feladatgenerátorIDk= listOf(
           // "statisztika",
            "alapm",
            "IrasbeliMuveletek",
            "IrasbeliMuveletekSimple",
            "SzamolasNegativSzamokkal",
            "tizhatvany",
            "tortek",
            "egyenletek",
            "egyenletrendszer",
            "romai",
            "aranyossag",
            "szazalek",
            "atvaltasok",
           // "geometriaszoveges",
            "geometriakockas",
            "koordinata",
            "fuggvenyleolvasas",
            "fuggvenyanalizis",
            "primek",
            "kombinatorika",
           // "szamrendszerek",
            "sorozatok",
            "algebraiazonossagok",
            "algebraiazonossagokdolgozat", //todo másik beépített böngészőt használni. Akár megnyitni önálló HTML dokumentumként...
            "SzovegesFeladatok",

        )
        val feladatgenerátorcímek = listOf(
         //   Res.string.title_activity_statisztika,//0
            Res.string.BasicOperations,//1
            Res.string.title_activity_irasbeli_muveletek,
            Res.string.title_activity_irasbeli_muveletek_simple,
            Res.string.title_activity_szamolas_negativ_szamokkal,
            Res.string.title_activity_tizhatvany,
            Res.string.PractisingFractions,//2
            Res.string.PracticingEquations,//3
            Res.string.PracticeSolvingEquationSystems,//4
            Res.string.practiceRomanNumerals,//5
            Res.string.proportionality,//6
            Res.string.percentage,//7
            Res.string.ConvertUnits,//8
         //   Res.string.GeometryTaskGenerator,//9
            Res.string.title_activity_geometria_kockas_lapon,//10
            Res.string.GetToKnowTheCoordinateSystem,//11
            Res.string.FunctionReading,//12
            Res.string.title_activity_function_analysis,//13
            Res.string.primefactors,//14
            Res.string.title_activity_kombinatorika,//15
          //  Res.string.title_activity_convert_number_base,//16
            Res.string.title_activity_sorozatok,//17
            Res.string.title_activity_algebrai_azonossagok,//18
            Res.string.title_activity_algebrai_azonossagok_dolgozat,//19
            Res.string.title_activity_szoveges_feladatok,


        //    Res.string.IrasbeliMuveletek
        ).mapIndexed { index, stringResource -> Pair(  stringResource(stringResource),
            feladatgenerátorIDk[index]
        ) } /*   val feladatgenerátorOsztályok = listOf<>( //itt lesz ideje activityk helyett más osztályokban gondolkozni
                /*   StatisztikaII::class.java,
                   Alapmuveletek::class.java,
                   tortek2::class.java,
                   EquationGenerator::class.java,
                   EquationSystemGenerator::class.java,
                   Romaiszamok::class.java,
                   Aranyossagok::class.java,
                   SzazalekII::class.java,
                   Atvaltasok::class.java,
                   GeometryTaskGenerator::class.java,
                   GeometriaKockasLapon::class.java,
                   CoordinateSystemIntroduction::class.java,
                   FunctionReading::class.java,
                   FunctionAnalysis::class.java,
                   PrimeFactors::class.java,
                   Kombinatorika::class.java,
                   ConvertNumberBase::class.java,
                   Sorozatok::class.java,
                   AlgebraiAzonossagok::class.java,
                   AlgebraiAzonossagokDolgozat::class.java*/
               )*/


        /*//todo megírni kmp-re a belépést valamikor
       composable("studentlogin") {

           Column(
               modifier = Modifier.fillMaxSize(),
               verticalArrangement = Arrangement.Center,
               horizontalAlignment = Alignment.CenterHorizontally
           ) {
               if (teacherselected.value == false) {
                   Text(text = stringResource(Res.string.enter_your_teacher_s_email))
                   TextField(
                       value = teacheremail.value,
                       onValueChange = { teacheremail.value = it },
                       keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Email)
                   )
                   Spacer(modifier = Modifier.height(16.dp))
                   Button(onClick = {
                       val query: Query = teachersRef.whereEqualTo(
                           "email",
                           teacheremail.value
                       )
                       query.get().addOnSuccessListener { documents ->
                           for (document in documents) {
                               teacherID.value = document.id
                               teacherselected.value = true
                               println("Document ID: ${document.id}")
                           }
                       }.addOnFailureListener { exception ->
                           // println("Error getting documents: ", exception)
                       }
                   }) {
                       Text(stringResource(Res.string.find_teacher_by_email))
                   }

               }
               var classSelected = remember { mutableStateOf(false) }

               if (teacherID.value != "" && classSelected.value == false) {//osztályválasztás
                   val teacherCollectionRef = teachersRef
                   val classes =
                       remember { mutableStateOf(listOf<StudentClass>()) }
                   if (teacherID != null) {
                       getClasses(
                           teacherCollectionRef,
                           classes,
                           teacherID.value,
                           context = this@ComposableMainMenu
                       )
                   }
                   var classScroll = rememberScrollState()
                   Column(
                       modifier = Modifier
                           .fillMaxSize()
                           .verticalScroll(classScroll),
                       verticalArrangement = Arrangement.Center,
                       horizontalAlignment = Alignment.CenterHorizontally,
                   ) {
                       Text(stringResource(Res.string.select_your_class))
                       //Text("These are classes of ${teacheremail.value}:")

                       for (studentclass in classes.value) {
                           Button(
                               onClick = {
                                   classSelected.value = true
                                   selectedclassdocumentID.value =
                                       studentclass.documentID
                                   selectedclassID.value = studentclass.classID
                                   // navController.navigate("studentsOfaClass/${studentclass.classID}/${studentclass.documentID}")
                               }) {
                               Text(text = studentclass.classID)
                           }
                       }
                   }
               }
               var studentselected = remember { mutableStateOf(false) }


               if (classSelected.value && studentselected.value == false) {
                   val studentCollectionRef = teacherID?.let { teacherId ->
                       selectedclassdocumentID?.let { classDocId ->
                           Firebase.firestore.collection("teachers")
                               .document(teacherId.value).collection("classes")
                               .document(classDocId.value).collection("students")
                       }
                   }
                   val students = remember { mutableStateOf(listOf<Student>()) }
                   if (studentCollectionRef != null) {
                       getStudents(
                           studentCollectionRef,
                           students,
                           this@ComposableMainMenu
                       )
                   }
                   var studentScroll = rememberScrollState()
                   Column(
                       modifier = Modifier
                           .fillMaxSize()
                           .verticalScroll(studentScroll),
                       verticalArrangement = Arrangement.Center,
                       horizontalAlignment = Alignment.CenterHorizontally
                   ) {

                       Text("These are the students of ${selectedclassID.value}.\n Select your account!")
                       Log.d("diáknevek0", students.value.toString())

                       for (student in students.value) {
                           Log.d("diáknevek", student.name)
                           Button(onClick = {
                               studentselected.value = true
                               studentname.value = student.name
                               studentID.value = student.studentID
                           }) {
                               Text(text = student.name)
                           }
                       }
                   }
               }
               LaunchedEffect(key1 = studentselected.value) {
                   if (studentselected.value) {
                       editor.putString("studentID", studentID.value)
                       editor.putString("studentname", studentname.value)
                       editor.putString("teacherID", teacherID.value)
                       editor.putString(
                           "selectedclassdocumentID",
                           selectedclassdocumentID.value
                       )
                       editor.apply()


                       navController.navigate("mainmenu")
                   }
               }
           }

       }*/




        Column(
            modifier = Modifier.verticalScroll(rememberScrollState()),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

          /*  Button(onClick = {
                navController.navigate("testarea")
            }){
                Text("test")
            }*/


            /*           var buttonModifier = Modifier.fillMaxWidth()  //belépéssel kapcsolatos kód

                       var loggedin = remember {
                           derivedStateOf {

                               if (studentname.value.isNotEmpty() && studentID.value.isNotEmpty() && teacherID.value.isNotEmpty() && selectedclassdocumentID.value.isNotEmpty()) {
                                   true
                               } else {
                                   false
                               }
                           }
                       }

                       if (loggedin.value) {
                           loggedindisplay(studentname,teacheremail,teacherID,teacherselected,selectedclassdocumentID,selectedclassID,studentID)
                       } else {
                           Button(onClick = { navController.navigate("studentlogin") }) {
                               Text(stringResource(Res.
                              string.studentlogin))
                           }
                       }

                       if (aboutRead == false) {
                           Button(
                               onClick = {
                                   startActivity(Intent(context, about::class.java))
                               },
                               modifier = Modifier
                                   .wrapContentWidth()
                                   .padding(5.dp)
                           ) {
                               Text(stringResource(Res.
                              string.about), letterSpacing = 0.sp)
                           }
                       }

       */
/*
            modulcsoport(
                title = stringResource(Res.string.taskgenerators), color = Color.Green
            ) {

                feladatgenerátorcímek.forEachIndexed { index, s ->
                    Button(onClick = {

                        navController.navigate(feladatgenerátorcímek[index].second.toString())
                        /*   startActivity(  //todo activity indítás helyett navigáljon oda a navhost
                               createIntentWithExtras(
                                   feladatgenerátorOsztályok[index],
                                   context,
                                   teacherID.value,
                                   selectedclassdocumentID.value,
                                   studentID.value
                               )
                           )*/
                    }) {
                        menuText(text = s.first)
                    }
                }

            }*/
                desktopmodulcsoport(stringResource(Res.string.taskgenerators),ratios) {//todo ratios felhasználásával kezdeni ezzel valamit
                    var buttonmodifier = Modifier.padding(1.dp)
                    if (ratios.widthtype==widthtype.small){
                        buttonmodifier = buttonmodifier.fillMaxWidth(0.9f)
                    }
                    feladatgenerátorcímek.forEachIndexed { index, s ->
                        Button(onClick = {
                            navController.navigate(feladatgenerátorcímek[index].second.toString())
                            /*   startActivity(  //todo activity indítás helyett navigáljon oda a navhost
                                   createIntentWithExtras(
                                       feladatgenerátorOsztályok[index],
                                       context,
                                       teacherID.value,
                                       selectedclassdocumentID.value,
                                       studentID.value
                                   )
                               )*/
                        },
                            modifier=buttonmodifier
                        ) {
                            desktopmenutext(text = s.first)
                        }
                    }
                }

            desktopmodulcsoport(title = stringResource(Res.string.games),ratios) {
                var buttonmodifier = Modifier.padding(1.dp)
                if (ratios.widthtype==widthtype.small){
                    buttonmodifier = buttonmodifier//.fillMaxWidth(0.9f)
                }

             /*   Button(
                    onClick = {

                        //  startActivity(Intent(context, MainActivity::class.java))
                    }, modifier = buttonModifier
                ) {
                    menuText(stringResource(Res.string.GeometryBlindMap))
                }*/
               Button(
                    onClick = {
                        navController.navigate("szogbecsles")
                    },
                   modifier=buttonmodifier   ) {
                   desktopmenutext(stringResource(Res.string.angle_estimation))
                }

                Button(
                    onClick = {
                        navController.navigate("exponentiation")
                    },
                    modifier=buttonmodifier    ) {
                    desktopmenutext(stringResource(Res.string.exponentiation))
                }


                Button(
                    onClick = {
                        navController.navigate("pyramid_screen")
                    },
                    modifier=buttonmodifier    ) {
                    desktopmenutext(stringResource(Res.string.title_activity_number_pyramid))
                }

                Button(
                    onClick = {
                        navController.navigate("szamkiraly")
                    },
                    modifier=buttonmodifier      ) {
                    desktopmenutext(stringResource(Res.string.MathKing))
                }

                Button(
                    onClick = {
                        navController.navigate("MagicSquare")
                    },
                    modifier=buttonmodifier    ) {
                    desktopmenutext(stringResource(Res.string.title_activity_magic_square))
                }




            }


           /* Button(
                onClick = {
                    /* startActivity(
                         Intent(
                             context,
                             OkosFeladatgyujtemeny::class.java
                         )
                     )*/
                }, modifier = buttonModifier
            ) {
                menuText(stringResource(Res.string.OkosFeladatGyűjtemény))
            }*/

          /*  Button(
                onClick = {
                    //    startActivity(Intent(context, Login::class.java))
                }, modifier = buttonModifier
            ) {
                menuText(stringResource(Res.string.logintxt, name))
            }*/


             //todo visszarakni a beállítások gombot
            Button(
                onClick = {
                    navController.navigate("settings")
                }
            ) {
                Row(verticalAlignment = Alignment.CenterVertically){
                    Text(stringResource(Res.string.title_activity_settings))
Icon(painter = painterResource(Res.drawable.settings), contentDescription = null, modifier = Modifier.size(24.dp))
                }
            }


            /* Button(onClick = {
                     startActivity(Intent(context, OssLicensesMenuActivity::class.java))
                 },
                     modifier= buttonModifier
                 ) {
                     menuText("open source licenses")
                 }*/

            if (aboutRead) {
                Button(
                    onClick = {
                        /*     startActivity(Intent(context, about::class.java))
                      */
                    }, modifier = Modifier.wrapContentWidth().padding(5.dp)
                ) {
                    Text(stringResource(Res.string.about), letterSpacing = 0.sp)
                }
            }

            /*  Button(onClick = { //todo ez soha nem lehet része a buildnek
                  startActivity(Intent(context, LaTexTest::class.java))
              },
                  modifier= buttonModifier
              ) {
                  menuText("LaTeXTest")
              }*/




         /*   Button( //todo visszarakni
                onClick = {
                    // startActivity(Intent(context, Licenses2::class.java))
                }
            ) {
               Text("Open source libraries used in the project")
            }*/


            //settingListItem(content = { }, alpha =.09f )
            Surface(
                shape = RoundedCornerShape(12.dp),
                modifier = Modifier
                    // .padding(8.dp)
                    .widthIn(200.dp,900.dp).padding(
                        top = 16.dp, start = 8.dp, end = 8.dp, bottom = 16.dp
                    ),
                color = getOnSurfaceColor().copy(.09f)//MaterialTheme.colorScheme.onSurface.copy(.09f)
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center,
                    modifier = Modifier.padding(
                        top = 6.dp, start = 2.dp, end = 2.dp, bottom = 6.dp
                    )
                ) {
                    Text(stringResource(Res.string.unkp), modifier = Modifier.weight(0.75f).padding(8.dp))
                    Image(
                        painterResource(Res.drawable.unkp_logo_04_resized),
                        contentDescription = null,
                        modifier = Modifier.padding(8.dp).size(100.dp).weight(.25f)
                    )
                }
            }


        }
    }


@OptIn(ExperimentalLayoutApi::class)
@Composable
fun desktopmodulcsoport(title: String, ratios: ratios, content: @Composable () -> Unit){
    Surface(
        shape = RoundedCornerShape(15),
        color = matekitcolor.copy(alpha = 0.2f),
        modifier = Modifier.padding(ratios.padding).widthIn(200.dp,1000.dp).wrapContentHeight()
    ){

        Column(verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally,modifier=Modifier.padding(top=16.dp, bottom = 16.dp)) {
            Text(
                title,
                fontSize = 30.sp,
                textAlign = TextAlign.Center,
                modifier = Modifier.fillMaxWidth(),
                fontWeight = FontWeight.Bold
            )
            FlowRow(horizontalArrangement = Arrangement.SpaceBetween, modifier = Modifier.widthIn(200.dp,1000.dp).wrapContentHeight().padding(ratios.padding),
                verticalArrangement = Arrangement.spacedBy(8.dp), // Space between rows
            ) {
              content()
            }
        }
    }
}

@Composable
fun modulcsoport(title: String, color: Color, content: @Composable() () -> Unit) {
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        Text(
            title,
            fontSize = 30.sp,
            textAlign = TextAlign.Center,
            modifier = Modifier.fillMaxWidth(),
            fontWeight = FontWeight.Bold
        )
        Surface(
            modifier = Modifier.fillMaxHeight().fillMaxWidth(0.98f),/*.padding(20.dp)*/
            //shape = Shapes,
            color = getOnPrimaryColor().copy(0.15f), //MaterialTheme.colorScheme.onPrimary.copy(0.15f),
            border = BorderStroke(3.dp, matekitcolor)

        ) {
            Column(modifier = Modifier.padding(6.dp)) {
                content()
            }
        }
    }
}

@Composable
fun feladatgenerátorok() {

}

@Composable
fun descriptionDialogButton(title: String, description: String) {
    var openDialog by remember { mutableStateOf(false) }

    IconButton(onClick = {
        openDialog = true
    }, modifier = Modifier.size(40.dp)) {
        Icon(painter = painterResource(Res.drawable.help), contentDescription = null)
    }
    AlertDialog(onDismissRequest = {
        openDialog = false
    }, title = {
        Text(text = title)
    }, text = {
        Text(
            description
        )
    }, confirmButton = {
        Row(
            modifier = Modifier.padding(all = 8.dp), horizontalArrangement = Arrangement.Center
        ) {
            Button(modifier = Modifier.fillMaxWidth(), onClick = { openDialog = false }) {
                Text(stringResource(Res.string.close))
            }
        }
    })
}

@Composable
fun loggedindisplay(
    studentname: MutableState<String>,
    teacheremail: MutableState<String>,
    teacherID: MutableState<String>,
    teacherselected: MutableState<Boolean>,
    selectedclassdocumentID: MutableState<String>,
    selectedclassID: MutableState<String>,
    studentID: MutableState<String>
) {
    Surface(
        shape = RoundedCornerShape(12.dp),
        modifier = Modifier.padding(8.dp).wrapContentHeight().fillMaxWidth(),
        color = getOnPrimaryColor().copy(0.15f)//MaterialTheme.colorScheme.onPrimary.copy(0.15f)
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Icon(
                Icons.Filled.AccountCircle,
                contentDescription = null,
                modifier = Modifier.size(50.dp)
            )
            Column(
                modifier = Modifier.wrapContentHeight().padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally

            ) {
                Text("Jelenleg belépve: ${studentname.value}")
                Button(onClick = {
                    teacheremail.value = ""
                    teacherID.value = ""
                    teacherselected.value = false
                    selectedclassdocumentID.value = ""
                    selectedclassID.value = ""
                    studentname.value = ""
                    studentID.value = ""

                }) {
                    Text(stringResource(Res.string.logout))
                }

            }
        }
    }
}

@Composable
fun desktopmenutext(text: String)
{
    Text(
        text = text,//.uppercase(),
        style = MaterialTheme.typography.titleLarge,
       // modifier=Modifier//.fillMaxWidth(),
        textAlign = TextAlign.Start
    )


}
/*
@Composable
expect fun getOnSurfaceColor(): Color

@Composable
expect fun getOnPrimaryColor(): Color*/


@Composable
fun menuText(text: String) {
    var textcolor = getOnSurfaceColor() //MaterialTheme.colorScheme.onSurface.value
    // CompositionLocalProvider(LocalContentColor provides MaterialTheme.colors.onSurface) {
    Text(
        text = text.uppercase(),
        textAlign = TextAlign.Start,
        modifier = Modifier.fillMaxWidth().padding(start = 5.dp),
        letterSpacing = 0.sp
    )
    //  }
}

