package matekit.matekit.taskgenerators

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.material3.Button
import androidx.compose.material3.Checkbox
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Slider
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import com.ionspin.kotlin.bignum.decimal.BigDecimal
import com.ionspin.kotlin.bignum.decimal.toBigDecimal
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.ui.LatexLogButton
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.drawButton
import matekit.ui.generatorSettings
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.MaxDistanceOfUnits
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.area
import matekit_multiplatform.composeapp.generated.resources.atvaltasTask
import matekit_multiplatform.composeapp.generated.resources.length
import matekit_multiplatform.composeapp.generated.resources.liquidMeasure
import matekit_multiplatform.composeapp.generated.resources.mass
import matekit_multiplatform.composeapp.generated.resources.volume
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.random.Random

class Atvaltasok(
    val navController:NavHostController,
    val TAG:String="atvaltas"
){
    @Composable
    fun content(
    ){
        var listsempty = rememberSaveable { mutableStateOf(0) }
        var váltandó = rememberSaveable { mutableStateOf(0) }
        var solution = rememberSaveable { mutableStateOf(0.toBigDecimal()) }
        var tipp = rememberSaveable { mutableStateOf("") }
        var indexOfFirstUnit = rememberSaveable { mutableStateOf(0) }
        var indexOfSecondUnit = rememberSaveable { mutableStateOf(0) }
        var task = rememberSaveable { mutableStateOf("") }
        var helyesMO = rememberSaveable { mutableStateOf(true) }
        var maxdistance = rememberSaveable { mutableStateOf(2) }
        var togenerate = rememberSaveable {
            mutableStateOf(true)
        }


        var isCorrect = rememberSaveable { mutableStateOf(false) }
        var numberofsolved = rememberSaveable { mutableStateOf(0) }
        var feedbackmessage = rememberSaveable { mutableStateOf("") }


        val lengthUnits = listOf<String>("mm", "cm", "dm", "m", "km")
        val areaUnits = listOf<String>("mm²", "cm²", "dm²", "m²", "ha", "km²")
        val volumeUnits = listOf<String>("mm³", "cm³", "dm³", "m³", "km³")
        val massUnits = listOf<String>("mg", "g", "dkg", "kg", "t")
        val urtaltalomUnits = listOf<String>("ml", "cl", "dl", "l", "hl")
        val lengthShiftNumbers = listOf<Int>(10, 10, 10, 1000, 1)
        val areaShiftNumbers = listOf<Int>(100, 100, 100, 10000, 100, 1)
        val volumeShiftNumbers = listOf<Int>(1000, 1000, 1000, 1000000000, 1)
        val urtartalomShiftNumbers = listOf<Int>(10, 10, 10, 100, 1)
        val massShiftNumbers = listOf<Int>(1000, 10, 100, 1000, 1)

        val massUnitfilter =
            remember { mutableStateListOf<Boolean>(true, true, true, true, true) }
        val lengthUnitfilter =
            remember { mutableStateListOf<Boolean>(true, true, true, true, true, true) }
        val areaUnitfilter =
            remember { mutableStateListOf<Boolean>(true, true, true, true, true, true) }
        val volumeUnitfilter =
            remember { mutableStateListOf<Boolean>(true, true, true, true, true) }
        val urtaltalomUnitfilter =
            remember { mutableStateListOf<Boolean>(true, true, true, true, true) }


        val unitfilter =
            remember { mutableStateListOf<Boolean>(true, true, true, true, true) }
        val mennyiségek = listOf<String>(
            stringResource(Res.string.mass),
            stringResource(Res.string.length),
            stringResource(Res.string.area),
            stringResource(Res.string.volume),
            stringResource(Res.string.liquidMeasure)
        )
        val unitfilters = rememberSaveable {
            listOf<SnapshotStateList<Boolean>>(
                massUnitfilter,
                lengthUnitfilter,
                areaUnitfilter,
                volumeUnitfilter,
                urtaltalomUnitfilter
            )
        }
        val unitnames = mutableListOf<List<String>>(
            massUnits,
            lengthUnits,
            areaUnits,
            volumeUnits,
            urtaltalomUnits
        )
        val shiftNumbers = mutableListOf<List<Int>>(
            massShiftNumbers,
            lengthShiftNumbers,
            areaShiftNumbers,
            volumeShiftNumbers,
            urtartalomShiftNumbers
        )

        var tasktext = rememberSaveable {
            mutableStateOf("")
        }

        MaterialTheme {
            Surface(
                modifier = Modifier.fillMaxSize(),
                color = MaterialTheme.colorScheme.background
            ) {
                val whiteboard = rememberSaveable { mutableStateOf(false) }
                val coroutineScope = rememberCoroutineScope()
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    borderedbasicfontsizeTextView(tasktext = tasktext.value)//todo feladatszöveg
                    Row(
                        horizontalArrangement = Arrangement.Center,
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        drawButton(drawboard = whiteboard)
                        Spacer(modifier = Modifier.width(10.dp))
                        universalCheckField(userInput = tipp.value, check = {
                            coroutineScope.launch {
                            check(tipp, solution, tasktext,isCorrect,numberofsolved,feedbackmessage)
                            generate(
                                0,
                                unitnames,
                                unitfilter,
                                shiftNumbers,
                                unitfilters,
                                tasktext,
                                indexOfFirstUnit,
                                indexOfSecondUnit,
                                maxdistance,
                                listsempty,
                                solution
                            )}
                        },
                            enterText = { tipp.value = it },

                        )
                        Spacer(modifier = Modifier.width(10.dp))

                        Button(onClick = {
                            coroutineScope.launch {
                                check(tipp, solution, tasktext,isCorrect,numberofsolved,feedbackmessage)
                                generate(
                                    0,
                                    unitnames,
                                    unitfilter,
                                    shiftNumbers,
                                    unitfilters,
                                    tasktext,
                                    indexOfFirstUnit,
                                    indexOfSecondUnit,
                                    maxdistance,
                                    listsempty,
                                    solution
                                )}
                            tipp.value =""
                        }) {
                            Text(text = stringResource(Res.string.Check))
                        }
                        Spacer(modifier = Modifier.width(10.dp))

                        LatexLogButton(navController = navController, value = TAG)
                    }
                   // val drawcontroller = rememberDrawController()

                    AnimatedVisibility(visible = whiteboard.value) {
                      //  matekit.matekit.whiteboard(activity = this@Atvaltasok, context =context , darkmode =darkmode, drawController =drawcontroller )
                    }
                    AnimatedVisibility(visible = !whiteboard.value) {
                        generatorSettings {
                            settingListItem(content = {
                                Column(
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center
                                ) {
                                    Text(text = "${stringResource(Res.string.MaxDistanceOfUnits)}: ${maxdistance.value}")
                                    Slider(
                                        value = maxdistance.value.toFloat(),
                                        onValueChange = { maxdistance.value = it.toInt() },
                                        steps = 3,
                                        valueRange = 1f..5f
                                    )
                                }
                            }, alpha = alpha2)
                            unitnames.forEachIndexed { index, strings ->
                                settingListItem(content = {
                                    mértékegységoption(
                                        name = mennyiségek[index],
                                        enabled = unitfilter[index],
                                        unitnames = strings,
                                        unitsenabled = unitfilters[index],
                                        oncheckchange = { unitfilter[index] = it }
                                    )
                                }, alpha = (if (index % 2 == 0) alpha1 else alpha2))
                            }
                        }
                    }

                }
                backButton(navController)
                responseToast(isCorrect.value,numberofsolved.value, completeResponse =  feedbackmessage.value, useCompleteFeedbackMessage = true)
            }
        }
        LaunchedEffect(togenerate) {
            if (togenerate.value) {
                generate(
                    0,
                    unitnames,
                    unitfilter,
                    shiftNumbers,
                    unitfilters,
                    tasktext,
                    indexOfFirstUnit,
                    indexOfSecondUnit,
                    maxdistance,
                    listsempty,
                    solution
                )
                togenerate.value = false
            }
        }
    }
    suspend fun generate(
        type: Int = 0,
        unitnames: List<List<String>>,
        unitfilter: List<Boolean>,
        shiftNumbers: List<List<Int>>,
        unitfilters: List<List<Boolean>>,
        tasktext: MutableState<String>,
        indexOfFirstUnit: MutableState<Int>,
        indexOfSecondUnit: MutableState<Int>,
        maxdistance: MutableState<Int>,
        listsempty: MutableState<Int>,
        solution: MutableState<BigDecimal>
    ) {
        var filteredunitnames = listOf<List<String>>()
        var filteredshiftNumbers = listOf<List<Int>>()
        var filteredfilters = listOf<List<Boolean>>()
        filteredunitnames = unitnames.filter { unitfilter[unitnames.indexOf(it)] }
        filteredshiftNumbers = shiftNumbers.filter { unitfilter[shiftNumbers.indexOf(it)] }
        filteredfilters = unitfilters.filter { unitfilter[unitfilters.indexOf(it)] }
        var indexOfFirstShiftNumber = 0
        var indexOfSecondShiftNumber = 0
        if (filteredunitnames.size == 0) return
        var ShiftNumberOfUnits = 1.toBigDecimal()
        var váltandó: Int = Random.nextInt(1, 500) //TODO a váltandó szám legyen különböző nagyságrendű az egyes mértékegységeknél
        var tasktype = if (type != 0) {
            type
        } else try {
            Random.nextInt(0, filteredunitnames.size)
        } catch (e: Exception) {
            return
        }

        var filtered = filteredunitnames[tasktype].filter {
            filteredfilters[tasktype][filteredunitnames[tasktype].indexOf(it)]
        }
        if (filtered.size < 2) {
            var keeptrying = false
            for (i in 0 until filteredunitnames.size) {
                var tesztfiltered =
                    filteredunitnames[i].filter { filteredfilters[i][filteredunitnames[i].indexOf(it)] }
                if (tesztfiltered.size >= 2) keeptrying = true
            }

            if (keeptrying) {
                generate(
                    type,
                    unitnames,
                    unitfilter,
                    shiftNumbers,
                    unitfilters,
                    tasktext,
                    indexOfFirstUnit,
                    indexOfSecondUnit,
                    maxdistance,
                    listsempty,
                    solution
                )
                return
            }
            return
        }
        indexOfFirstUnit.value = Random.nextInt(0, filtered.size)
        indexOfSecondUnit.value = Random.nextInt(0, filtered.size - 1)
        indexOfSecondUnit.value =
            if (indexOfSecondUnit.value >= indexOfFirstUnit.value) indexOfSecondUnit.value + 1 else indexOfSecondUnit.value

        var firstunit = filtered[indexOfFirstUnit.value]
        var secondunit = filtered[indexOfSecondUnit.value]
        indexOfFirstShiftNumber = filteredunitnames[tasktype].indexOf(firstunit)
        indexOfSecondShiftNumber = filteredunitnames[tasktype].indexOf(secondunit)

        //shiftNumbers[tasktype][unitnames[tasktype].indexOf([unitnames[tasktype][indexOfFirstUnit])]

        /*indexOfFirstUnit= Random.nextInt(0,unitnames[tasktype].size)
        indexOfSecondUnit=Random.nextInt(0,unitnames[tasktype].size-1)*/


        var primo =
            if (indexOfFirstShiftNumber > indexOfSecondShiftNumber) indexOfSecondShiftNumber else indexOfFirstShiftNumber
        var secundo =
            if (indexOfFirstShiftNumber > indexOfSecondShiftNumber) indexOfFirstShiftNumber else indexOfSecondShiftNumber
        if (secundo - primo > maxdistance.value) {
            var keepgenerating = false
            for (i1 in 0 until filteredunitnames.size) {
                //   var akármi = unitnames.indexOf(filteredunitnames[i1])
                var tesztfiltered = filteredunitnames[i1].filter {
                    filteredfilters[i1][filteredunitnames[i1].indexOf(it)]
                }
                if (tesztfiltered.size >= 2) {
                    for (i in 0 until tesztfiltered.size - 1) {
                        var filteredfirst =
                            filteredunitnames[i1].indexOf(tesztfiltered[i]) //az eredeti listában elfoglalt helye annak, ami az első(most már i-edik) a filtered listában
                        var filteredsecond = filteredunitnames[i1].indexOf(tesztfiltered[i + 1])
                        if (filteredsecond - filteredfirst <= maxdistance.value) keepgenerating =
                            true
                    }
                }
            }
            /* var filteredfirst =
                 unitnames[tasktype].indexOf(filtered[0]) //az eredeti listában elfoglalt helye annak, ami az első a filtered listában
             var filteredsecond = unitnames[tasktype].indexOf(filtered.last())*/
            if (keepgenerating) {
                generate(
                    type,
                    unitnames,
                    unitfilter,
                    shiftNumbers,
                    unitfilters,
                    tasktext,
                    indexOfFirstUnit,
                    indexOfSecondUnit,
                    maxdistance,
                    listsempty,
                    solution
                )//eredetileg ez a tasktype-os
                return
            } else return
        }


        listsempty.value = 0
        for (i in primo until secundo) {
            ShiftNumberOfUnits *= filteredshiftNumbers[tasktype][i]
        }

        solution.value = if (indexOfFirstShiftNumber < indexOfSecondShiftNumber) {
            váltandó.toBigDecimal() * ShiftNumberOfUnits
        } else váltandó.toBigDecimal() / ShiftNumberOfUnits


        tasktext.value = getString(
            Res.string.atvaltasTask,
            filtered[indexOfFirstUnit.value],
           váltandó,
            filtered[indexOfSecondUnit.value]
        )

       // Log.d(TAG, solution.value.toString())
    }

    suspend fun check(
        tipp: MutableState<String>,
        solution: MutableState<BigDecimal>,
        tasktext: MutableState<String>,
        isCorrect: MutableState<Boolean>,
        numberofsolved: MutableState<Int>,
        feedbackmessage: MutableState<String>
    ) {

        /*try {
            tipp = binding.atvaltasTipp.editText?.text.toString().toDouble()
        } catch (e: Exception) {
            return
        }*/

        var helyesMO = tipp.value.replace(".",",") == solution.value.toPlainString().replace(".",",")
        isCorrect.value= helyesMO

        var showSolutionIfUserWrong = getString(Res.string.WrongSolution, solution.value.toPlainString())
        feedbackmessage.value = if (helyesMO) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong
        numberofsolved.value +=1
        LaTexLogWriteDesktop(
            isCorrect.value,
            tipp.value,
            showSolutionIfUserWrong,
            TAG,
            tasktext.value,
            taskLaTexWrap = false
        )
        tipp.value=""
    }
    /*fun findinsertplace(listindex:Int, indexOfinsertedUnit:Int):Int{
        // while (unitnames[listIndex]!=)
        unitnames[listindex].forEachIndexed { index, s ->
            var mértékegységindexeaunitlistán =0
            unitlists[listindex].forEachIndexed { index2, unit ->
                if(s==unit.name){ mértékegységindexeaunitlistán = index2
                    if (mértékegységindexeaunitlistán >indexOfinsertedUnit){ //akkor emögé akarjuk beszúrni a unitnevünket, nagyon fontos, hogy csak egyszer
                        return index
                        //TODO megbeszélni, hogy ez optimális kód-e
                    }
                }
            }
        }
        return unitnames[listindex].size
    }*/


@Composable
fun mértékegységoption(
    name: String,
    enabled: Boolean,
    unitnames: List<String>,
    unitsenabled: SnapshotStateList<Boolean>,
    oncheckchange: (Boolean) -> Unit
) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(checked = enabled, onCheckedChange = {
                oncheckchange(it)
                if (enabled) {//pont ellentétesen kezeli valamiért ezt a logikai értéket ¯\_(ツ)_/¯
                    for (i in 0 until unitsenabled.size) {
                        unitsenabled[i] = false
                    }
                } else {
                    for (i in 0 until unitsenabled.size) {
                        unitsenabled[i] = true
                    }
                }

            })
            Text(text = name)
        }
        var scroll = rememberScrollState()
        AnimatedVisibility(visible = enabled) {
            Row(
                horizontalArrangement = Arrangement.SpaceAround,
                modifier = Modifier.horizontalScroll(scroll)
            ) {
                unitnames.forEachIndexed { index, s ->

                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Checkbox(checked = unitsenabled[index], onCheckedChange = {
                            unitsenabled[index] = it
                        })
                        Text(text = s)
                    }

                }
            }
        }
    }
}

}