package matekit.ui

import androidx.compose.foundation.content.MediaType.Companion.Text
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.navigation.NavHostController
import com.ionspin.kotlin.bignum.decimal.toBigDecimal


@Composable
fun testarea(navController: NavHostController) {

    backButton(navController)

    var a = 5.toBigDecimal()
    var denominator = "10000000000000000000000000000".toBigDecimal()
    Text("a/denominator = ${(a / denominator).toPlainString()}")

    //val tesztpontok = listOf()
}