package matekit.matekit

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import matekit.matekit.utilityfunctions.getlog
import matekit.ui.SurfaceWBorder
import matekit.ui.theme.matekitcolor
import matekit.ui.webviewkmm
import matekit_multiplatform.composeapp.generated.resources.Clear
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.lastCorrects
import org.jetbrains.compose.resources.stringResource
//import java.io.File

@Composable
fun simpleLogUI(navController: NavHostController, type:String){
    val userName = "user1"
    val userIndex = 1
    val name = "${userName}_$userIndex"
    val logKey = "${name}_$type"

    // Retrieve and deserialize the logs
    val logs = remember { mutableStateOf(getLogs(logKey)) }
    val szoveg = remember { mutableStateOf("") }

    // Reverse the logs for display
    val reversedLogs = remember { mutableStateOf(logs.value.reversed()) }

    // Build the display string
    var i = 1
    reversedLogs.value.forEach {
        szoveg.value += it
        /*   if (i % 3 == 0) */szoveg.value += "<br> ------------------------------------------------ <br>" //androidon soronként olvastuk be, így 3 sortörés volt feladatoenként, ami itt nincs
        i++
    }

    // Count correct answers
    val n = 10
    val countLogs = reversedLogs.value.take(30)
    var corrects = 0
    countLogs.forEach {
        if (it.contains("✓")) corrects++
    }

    // UI Components
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Bottom,
        modifier = Modifier.fillMaxSize()
    ) {
       LaunchedEffect(szoveg.value){
           val simpleLogDiv = document.getElementById("simpleLog")
           simpleLogDiv?.innerHTML = szoveg.value
       }

        Row(
            modifier = Modifier.fillMaxWidth(),//webes felületen kicsit máshogy praktikus felépíteni a UI-t
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            FloatingActionButton(
                modifier = Modifier.align(Alignment.CenterVertically),
                onClick = {
                    navController.popBackStack()

                    val mathContentDiv = document.getElementById("simpleLog") //töröljük a mathdivet
                    mathContentDiv?.innerHTML = ""
                  //  js("MathJax.Hub.Queue(['Typeset', MathJax.Hub, mathContentDiv]);")
                },
                containerColor = Color.Gray
            ) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = "Back"
                )
            }

            SurfaceWBorder(content = {
                Column(
                    modifier = Modifier.fillMaxWidth(0.6f),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        text = stringResource(Res.string.lastCorrects, n, corrects),
                        fontSize = 20.sp,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.padding(4.dp),
                    )
                }
            }, borderColor = matekitcolor,Modifier.padding(6.dp).fillMaxWidth(.6f))

            Spacer(Modifier.width(16.dp))

            Button(onClick = {
                localStorage.setItem(logKey, "")//ez működik
                logs.value = emptyList()
                szoveg.value = ""

                // Clear the math content div
                val mathContentDiv = document.getElementById("simpleLog")
                mathContentDiv?.innerHTML = ""//nem működik, de nem sürgős

                // Re-render MathJax content
              //  js("MathJax.Hub.Queue(['Typeset', MathJax.Hub, mathContentDiv]);")
                window.location.reload()//nem szép, nem elegáns, de működik

            }) {
                Text(stringResource(Res.string.Clear))
            }
        }
    }

    // Back Button (Adjust as needed)
    //  backButton(navController)
}

