package matekit.matekit.games

import androidx.compose.animation.animateColorAsState
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.RangeSlider
import androidx.compose.material3.Slider
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import com.dokar.sonner.rememberToasterState
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.generatorSettings
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.settingtext
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Addition
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.Multiplication
import matekit_multiplatform.composeapp.generated.resources.NInterval
import matekit_multiplatform.composeapp.generated.resources.NewTask
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.rows
import org.jetbrains.compose.resources.stringResource
import kotlin.time.Duration.Companion.milliseconds


@Composable
fun pyramidscreen(
    navController: NavHostController,
    focusedTextField: MutableState<MutableState<TextFieldValue>?>
) {




    var NsliderPosition by remember { mutableStateOf(4f) }
    val n by remember { derivedStateOf { NsliderPosition.toInt() } }
    var sliderPosition by remember { mutableStateOf(10f..50f) }
    val numberRange by remember { derivedStateOf { (sliderPosition.start.toInt()..sliderPosition.endInclusive.toInt()) } }
    var operation by remember { mutableStateOf(0) }
    val pyramidLogic =
        rememberSaveable { mutableStateOf(PyramidLogic(n, numberRange, operation)) }

    Column(modifier = Modifier.padding(20.dp), horizontalAlignment = Alignment.CenterHorizontally) {

        generatorSettings {
            Column() {
                settingListItem(
                    {
                        Row() {
                            Column() {
                                settingtext(stringResource(Res.string.rows) + ": ${NsliderPosition.toInt()}")
                                Slider(
                                    value = NsliderPosition,
                                    onValueChange = { NsliderPosition = it },
                                    valueRange = 3f..15f/*, steps = 1*/
                                )
                            }

                        }
                    },
                    alpha1
                )
                settingListItem(content = {
                    Row() {
                        Column() {
                            settingtext("${stringResource(Res.string.NInterval)} ${sliderPosition.start.toInt()}..${sliderPosition.endInclusive.toInt()}")
                            RangeSlider(
                                value = sliderPosition,
                                onValueChange = { sliderPosition = it },
                                valueRange = 2f..250f,
                                /* steps = 10*/
                            )
                        }

                    }
                }, alpha = alpha2)
                settingListItem(content = {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        Button(onClick = { operation = 0 }) {
                            Text(text = stringResource(Res.string.Addition))
                        }
                        Spacer(modifier = Modifier.width(15.dp))
                        Button(onClick = { operation = 1 }) {
                            Text(text = stringResource( Res.string.Multiplication))

                        }
                    }
                }, alpha = alpha1)
            }
        }




        Column(
            modifier = Modifier
                .fillMaxSize()
                .verticalScroll(rememberScrollState()),
            verticalArrangement = Arrangement.Center
        ) {
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .horizontalScroll(rememberScrollState()),
                horizontalArrangement = Arrangement.Center
            ) {
                pyramidUI(
                    pyramid = pyramidLogic,
                    n,
                    numberRange,
                    operation,
                    focusedTextField
                                    )
            }
        }
    }
    backButton(navController)

}


@Composable
fun pyramidUI(
    pyramid: MutableState<PyramidLogic>,
    n: Int,
    numberRange: IntRange,
    operation: Int,
    focusedTextField: MutableState<MutableState<TextFieldValue>?>,//amit kap az egy Mutablestate<mutablestateof(textfieldvalue)>
) {
    val done = rememberSaveable { mutableStateOf(false) }//csak akkor lesz igaz, ha kijavítottunk egy teljesen kitöltött piramist
    val solvednumber = remember { mutableStateOf(0) }
    val correct = remember { mutableStateOf(false) }//akkor igaz, ha a megoldás helyes
    responseToast(correct = correct.value, numberofsolved = solvednumber.value,nosolution=true)

    Column(
        modifier = Modifier
            .aspectRatio(1f)
            .fillMaxHeight(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        for (i in pyramid.value.n - 1 downTo 0) {
            var rowfill =
                1f / (i + 1) //pyramid.value.n.toFloat()/i//i.toFloat()/(pyramid.value.n-1)
            Row(
                modifier = Modifier/*.fillMaxWidth(rowfill)*/,
                horizontalArrangement = Arrangement.Center
            ) {
                for (j in 0 until pyramid.value.n - i) {
                    val currentbrick = pyramid.value.userPyramid.listofUserRows2[i][j].value

                    val focusRequester = remember { FocusRequester() }
                    val textField = remember { mutableStateOf(TextFieldValue(currentbrick.text.value)) }

                    Brick(
                        borderColor = currentbrick.color.value,
                     //   textfield = /*remember{mutableStateOf("$i,$j")}*//*currentbrick.text*///textField,//todo ez kell a képernyőbillentyűzethez majd
                        text=currentbrick.text,
                        label = currentbrick.label,
                        currentbrick.readonly,
                        i + 1,
                        focusRequester
                    ){
                        focusedTextField.value = textField
                    }
                }
            }
        }
        Spacer(modifier = Modifier.height(20.dp))
        Row() {
            val toaster = rememberToasterState()

            LaunchedEffect(done.value) {
                if(done.value){
                    if (correct.value) {
                        toaster.show("correct",duration= 2000.milliseconds)
                    }else{
                        toaster.show("wrong",duration= 2000.milliseconds)
                    }
                }
            }

            Button(
                onClick = {
                      //  println("check clicked!")
                        done.value = pyramid.value.userPyramid.compare(correct)
                       if(done.value) solvednumber.value++
                    //println("done: $done")
                },
                enabled = !done.value
            ) {
                Text(text = stringResource(Res.string.Check))
            }

            if (done.value) {
                Spacer(modifier = Modifier.width(10.dp))
                Button(onClick = {
                    pyramid.value = PyramidLogic(n, numberRange, operation)
                    done.value = false
                }) {
                    Text(text = stringResource(Res.string.NewTask))
                }
            }
        }
    }
}


class PyramidLogic(
    var n: Int,
    var numberRange: IntRange,
    var operation: Int = 0
)  { //n is the length of the bottom row of the pyramid
    var listoRows = mutableListOf<MutableList<Int>>(
    )

    fun createPyramid() {
        listoRows = mutableListOf<MutableList<Int>>(
        )
        val bottomRow = mutableListOf<Int>()
        for (i in 1..n) {
            val bottomRowNumber = numberRange.random()
            bottomRow.add(bottomRowNumber)
            // Log.d("számpiramis", bottomRowNumber.toString())

        }
        listoRows.add(bottomRow)
        while (listoRows.last().size > 1) {
            var newList = mutableListOf<Int>()
            for (i in 1 until listoRows.last().size) {

                var newNum =
                    if (operation == 0) listoRows.last()[i] + listoRows.last()[i - 1] else listoRows.last()[i] * listoRows.last()[i - 1]
                newList.add(newNum)
            }
           // Log.d("számpiramis", newList.toString())

            listoRows.add(newList)
        }
        /* listoRows.forEachIndexed { index, intMutableList ->
             println("$index $intMutableList")
         }*/
    }

    init {
        createPyramid()
    }

    inner class UserPyramid() {

        var listofUserRows2 =
            mutableListOf<MutableList<MutableState<Brickdata>>>() //the first mutable list in the type refers to the row. the second list contains each brick

        init {
            listoRows.forEachIndexed { index, strings ->

                listofUserRows2.add(mutableListOf<MutableState<Brickdata>>())
                strings.forEach {
                    var generated = try {
                        if (index == 0) it.toString() else "" /*we fill the bottom row with numbers, and the rest with empty strings
                      later we would like to be able to select them randomly */
                    } catch (e: Exception) {
                        ""
                    }
                    listofUserRows2[index].add(
                        mutableStateOf(
                            Brickdata(

                                text = mutableStateOf(
                                    generated
                                ),
                                color = mutableStateOf(Color.Gray),
                                label = mutableStateOf(""),
                                readonly = mutableStateOf(generated != "")
                            )
                        )
                    )

                }
            }

        }




         fun compare(
             Correct: MutableState<Boolean>
         ): Boolean {
             //println("comparing....")


            listofUserRows2.forEach { brickdatalists ->
             //   println("brickdatalists.size=${brickdatalists.size}")
                brickdatalists.forEach {
                //    println("it.value.text.value=${it.value.text.value}")
                    if (it.value.text.value == "") return false
                }
            }
             //println("pyramid not empty")
            var correct = true
            listoRows.forEachIndexed { rownumber, ints ->
                ints.forEachIndexed { brickindex, bricknumber ->


                    if (bricknumber.toString() != listofUserRows2[rownumber][brickindex].value.text.value) {
                        correct = false
                        listofUserRows2[rownumber][brickindex].value.apply {
                            this.color.value = Color.Red
                            this.label.value = bricknumber.toString()
                        }
                    } else {
                        listofUserRows2[rownumber][brickindex].value.apply {
                            this.color.value = Color.Green
                            this.label.value = "✓".toString() //"✔"✓✓
                        }
                    }
                }
            }
             Correct .value = correct
            listofUserRows2.forEach { brickdatalists ->
                brickdatalists.forEach {

                    it.value.readonly.value = true
                }
            }

            //println("return check")
            return true
        }

        inner class Brickdata(
            var text: MutableState<String>,
            var color: MutableState<Color>,
            var label: MutableState<String>,
            var readonly: MutableState<Boolean>
        )


    }

    var userPyramid = UserPyramid()

}









@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun Brick(
    borderColor: Color,
    text: MutableState<String>,
   // textfield: MutableState<TextFieldValue>,
    label: MutableState<String>,
    readonly: MutableState<Boolean>,
    n: Int,
    focusRequester: FocusRequester,
    onFocus: () -> Unit // Pass the onFocus callback
) {

    val animateFocusedColor by animateColorAsState(targetValue = matekitcolor)
    val animateunFocusedColor by animateColorAsState(targetValue = borderColor)
    OutlinedTextField(
        modifier = Modifier.width(80.dp).focusRequester(focusRequester).onFocusChanged { focusState ->
            if (focusState.isFocused) {
                         onFocus() // Call the onFocus callback
            }
        },//,fillMaxWidth(1f/(n.toFloat()))
        value = text.value,
        label = { Text(text = label.value) },
        keyboardOptions = KeyboardOptions(
            keyboardType = KeyboardType.Number,
            imeAction = ImeAction.Done
        ),
        textStyle = LocalTextStyle.current.copy(textAlign = TextAlign.Center),
        readOnly = readonly.value,
        enabled = !readonly.value,
        onValueChange = {
            text.value = it
            //todo itt kezeljük, hogy a textfieldvalue és a text.value legyen ugyanaz, és akkor működni fog a saját képernyőbillentyűzetünkkel
            //   textfield.value = TextFieldValue(it)
        },
        colors = OutlinedTextFieldDefaults.colors( //weben másik API-t kellett használni
            disabledTextColor = LocalContentColor.current.copy(),
            disabledBorderColor = animateunFocusedColor,
            disabledLabelColor = animateunFocusedColor,
            focusedBorderColor = animateFocusedColor,
            unfocusedBorderColor = animateunFocusedColor
        ),

        )
}




