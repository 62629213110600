package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.matekit.utilityfunctions.removetrailingzeros
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.responseToast
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.random.Random

data class SzamolasNegativSzamokkalUIState(
    val feladat: String = "",
    val solution: String = "",
    val showsolution: String = "",
    val numberofsolved: Int = 0,
    val isCorrect: Boolean = false,
    val userInput: String = ""
)


@Composable
fun SzamolasNegativSzamokkalUI(navcontroller: NavHostController, TAG: String,) {
    Surface(
        modifier = Modifier.fillMaxSize(), color = MaterialTheme.colorScheme.background
    ) {
        val vm = viewModel { SzamolasNegativSzamokkalViewModel( TAG) }
        val uiState by vm.uiState.collectAsState()
        val coroutineScope = rememberCoroutineScope()
        LaunchedEffect(Unit) {
            vm.generateNewTask()
        }
        Column(modifier = Modifier.fillMaxSize()) {

            borderedbasicfontsizeTextView(tasktext = uiState.feladat,)

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Center
            ) {

                universalCheckField(
                    uiState.userInput,
                    enterText = { vm.entertext(it) },
                    check = {coroutineScope.launch { vm.check()  } }
                )

                Spacer(Modifier.width(8.dp))
                Button(
                    onClick = {coroutineScope.launch { vm.check()  } }
                    ,
                ) {
                    Text(stringResource(Res.string.Check))
                }
                LatexLogButton(navcontroller,TAG)
            }
        }
        backButton(navcontroller)
        responseToast(uiState.isCorrect,uiState.numberofsolved, completeResponse =  uiState.showsolution, useCompleteFeedbackMessage = true)
    }
}

class SzamolasNegativSzamokkalViewModel( tag: String) : ViewModel() {
    val tag = tag


    private val _uiState = MutableStateFlow(SzamolasNegativSzamokkalUIState())
    val uiState: StateFlow<SzamolasNegativSzamokkalUIState> = _uiState.asStateFlow()

    fun generateNewTask() {


        var number1:Int =(1..10).random()
        var negatív = Random.nextBoolean()//eldöntjük hogy negatív legyen-e az első szám
        if(negatív) number1=-number1

        var number2list = listOf<Number>()
        repeat(8){
            number2list+= (1..10).random()
        }
        repeat(2){
            val decimal:Double = (10..100).random()/ 10.0
            number2list +=decimal
        }
        var task:String
        var number2:Double = number2list.random().toDouble()
        negatív = Random.nextBoolean()//eldöntjük, hogy negatív legyen-e a második szám
        if(negatív) number2=-number2
        if(number1>0 && number2>0) number2 = -number2 //ne legyen mindkettő pozitív, mert nincs a feladatnak értelme

        val összeadás = Random.nextBoolean()//eldöntjük, hogy összeadás-e
        if (összeadás ==false){
            if(number2<0){//ha negatív számot vonunk ki, akkor rakjuk zárójelbe majd
                task="${number1.toString().removetrailingzeros()}-(${number2.toString().removetrailingzeros()})"
            }else{//akkor a második szám nagyobb, mint nulla
                task="${number1.toString().removetrailingzeros()}-${number2.toString().removetrailingzeros()}"//ha pozitív, akkor nem kell zárójel
            }
        }else{
            if(number2<0){//ha nem akarjuk összevonni az összeadást meg a kivonást
                task="${number1.toString().removetrailingzeros()}+(${number2.toString().removetrailingzeros()})"
            }else{
                task="${number1.toString().removetrailingzeros()}+${number2.toString().removetrailingzeros()}"
            }
        }
        var solution = if(összeadás) number1.toDouble()+number2 else number1.toDouble()-number2
        var roundedSolution = solution.formatToDecimals(1)

        var haromszam = (1..100).random()
        if(haromszam<=60)//erre 40% az esély
        {
            task=""
            var szamok = List(3) { (-10..-2).random() }.toMutableList()//3 db negatív szám -10 és -2 között
            var invert = Random.nextBoolean()
            if (invert) szamok[0] = -szamok[0]//az egyiket invertáljuk
            szamok.shuffle()//megkeverjük, hogy melyik legyen pozitív
            var műveletek = listOf(
                "*",
                listOf("+", "-").random()
            ).shuffled()//lesz egy szorzás, megy egy összeadás vagy kivonás
            task += szamok[0]//első szám
            task += műveletek[0]//első művelet
            //ha negatív számot vonunk ki, akkor rakjuk zárójelbe. csak két szám van hátra, így nem írok rá külön függvényt
            if (szamok[1] < 0) task += "(${szamok[1]})" else task += szamok[1]//második szám
            task += műveletek[1]//második művelet
            if (szamok[2] < 0) task += "(${szamok[2]})" else task += szamok[2]//harmadik szám

            solution =  eval(task)
            task = task.replace("*","*".toMultiplicationdot())
            roundedSolution = solution.formatToDecimals(1)

        }



        _uiState.update {
            it.copy(
                feladat = task,
                solution =roundedSolution
            )
        }
    }


    fun entertext(enteredText: String) {
        _uiState.update {
            it.copy(
                userInput = enteredText
            )
        }
    }

    suspend fun check() {
        val solution = uiState.value.solution.replace(".", ",")//todo ezt majd lokalizációval kezelni kell
        val userInput = uiState.value.userInput.replace(".", ",").removetrailingzeros()//todo ezt majd lokalizációval kezelni kell


        val isCorrect: Boolean
        if (userInput == solution) {
            isCorrect = true
        } else {
            isCorrect = false
        }
        val showSolutionIfUserWrong = getString(Res.string.WrongSolution, solution)
        _uiState.update {
            it.copy(
                isCorrect = isCorrect,
                numberofsolved = uiState.value.numberofsolved + 1,
                showsolution = showSolutionIfUserWrong
            )
        }

        LaTexLogWriteDesktop(
            isCorrect,
            _uiState.value.userInput.toString(),
            showSolutionIfUserWrong,
            tag,
            _uiState.value.feladat,
        )
        generateNewTask()
        entertext("")
    }


}
